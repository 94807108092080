import styled from '@emotion/styled';
import KVImageSp from 'assets/images/about/kv-sp.png';
import KVImage from 'assets/images/about/kv.png';
import MfsImg from 'assets/images/about/mfs.png';
import { Arrow } from 'components/atoms/arrow';
import { Button } from 'components/atoms/button';
import { EnHeading } from 'components/atoms/enHeading';
import { JaHeading } from 'components/atoms/jaHeading';
import { IndexLink } from 'components/molecules/indexLink';
import { StudioCard } from 'components/molecules/studioCard';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import FadeUpSection from 'components/organisms/fadeUpSection';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { graphql } from 'gatsby';
import * as React from 'react';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import sizes from 'style/sizes';
import { contentWidthPc, mq } from 'style/variables';
import { AboutQuery } from 'types/graphql-types';
import SEO from 'util/seo';
import { useIsSp } from 'util/useIsSp';

const links = [
  { name: 'Company', link: '/en/about/company' },
  { name: 'Studios', link: '/en/about/studios' },
  { name: 'Vision', link: '/en/about/vision' },
  { name: 'Culture', link: '/en/about/culture' },
  { name: 'Sustainability', link: '/en/about/sustainability' },
];
const ABOUT_TEXT_SVG_PC = `
<svg width="790" height="360" viewBox="0 0 790 360" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_207_8715)">
<path d="M484.705 343.917C482.749 343.917 480.945 343.458 479.294 342.541C477.704 341.624 476.45 340.37 475.533 338.781C474.616 337.13 474.157 335.326 474.157 333.369C474.157 331.412 474.616 329.639 475.533 328.049C476.45 326.459 477.704 325.206 479.294 324.288C480.945 323.31 482.749 322.821 484.705 322.821C486.723 322.821 488.527 323.31 490.117 324.288C491.707 325.206 492.96 326.459 493.878 328.049C494.795 329.639 495.253 331.412 495.253 333.369C495.253 335.326 494.795 337.13 493.878 338.781C492.96 340.37 491.707 341.624 490.117 342.541C488.527 343.458 486.723 343.917 484.705 343.917Z" fill="#1A1311"/>
<path d="M428.802 290.626H447.88L450.999 309.338V343H432.287V308.237L428.802 290.626ZM475.58 289.801V305.76C473.746 305.394 472.003 305.149 470.352 305.027C468.762 304.843 467.295 304.751 465.949 304.751C463.259 304.751 460.782 305.302 458.52 306.402C456.257 307.442 454.423 309.185 453.017 311.631C451.671 314.077 450.999 317.348 450.999 321.445L447.421 317.042C447.911 313.251 448.644 309.704 449.623 306.402C450.601 303.039 451.916 300.074 453.567 297.505C455.279 294.876 457.389 292.828 459.896 291.36C462.403 289.831 465.46 289.067 469.068 289.067C470.108 289.067 471.178 289.128 472.278 289.25C473.379 289.373 474.48 289.556 475.58 289.801Z" fill="#1A1311"/>
<path d="M401.337 344.467C394.977 344.467 389.29 343.306 384.276 340.982C379.323 338.658 375.41 335.417 372.536 331.259C369.662 327.04 368.225 322.148 368.225 316.584C368.225 311.141 369.6 306.372 372.352 302.275C375.104 298.117 378.864 294.876 383.634 292.552C388.465 290.229 393.907 289.067 399.961 289.067C406.259 289.067 411.609 290.443 416.012 293.194C420.476 295.885 423.9 299.707 426.285 304.66C428.731 309.613 429.954 315.452 429.954 322.179H383.359V310.897H419.406L413.26 314.841C413.016 312.15 412.343 309.888 411.243 308.053C410.142 306.158 408.674 304.721 406.84 303.743C405.067 302.764 402.926 302.275 400.419 302.275C397.668 302.275 395.313 302.825 393.357 303.926C391.4 305.027 389.871 306.555 388.771 308.512C387.67 310.408 387.12 312.64 387.12 315.208C387.12 318.51 387.853 321.323 389.321 323.646C390.85 325.909 393.051 327.652 395.925 328.875C398.86 330.098 402.437 330.709 406.656 330.709C410.509 330.709 414.331 330.22 418.122 329.241C421.974 328.202 425.46 326.765 428.578 324.93V336.854C424.97 339.3 420.843 341.196 416.196 342.541C411.609 343.825 406.656 344.467 401.337 344.467Z" fill="#1A1311"/>
<path d="M306.468 272.374H325.271V343H306.468V272.374ZM344.441 289.067C349.516 289.067 353.797 290.076 357.282 292.094C360.829 294.112 363.55 296.955 365.445 300.624C367.341 304.293 368.289 308.635 368.289 313.649V343H349.486V316.4C349.486 312.487 348.477 309.491 346.459 307.412C344.502 305.333 341.659 304.293 337.929 304.293C335.36 304.293 333.098 304.843 331.141 305.944C329.246 307.045 327.778 308.604 326.738 310.622C325.76 312.64 325.271 315.025 325.271 317.776L319.401 314.749C320.134 309.185 321.694 304.507 324.079 300.716C326.463 296.863 329.398 293.959 332.884 292.002C336.431 290.045 340.283 289.067 344.441 289.067Z" fill="#1A1311"/>
<path d="M252.118 304.476V296.313L262.758 292.002L270.83 276.501H281.469V290.626H303.483V304.476H281.469V319.886C281.469 323.677 282.234 326.337 283.762 327.866C285.291 329.394 288.073 330.159 292.109 330.159C294.678 330.159 296.94 329.945 298.897 329.517C300.915 329.028 302.749 328.416 304.4 327.682V341.808C302.566 342.48 300.15 343.092 297.154 343.642C294.158 344.192 291.07 344.468 287.89 344.468C282.081 344.468 277.311 343.581 273.581 341.808C269.912 340.034 267.191 337.497 265.418 334.195C263.645 330.831 262.758 326.918 262.758 322.454V304.476H252.118Z" fill="#1A1311"/>
<path d="M227.568 344.468C221.209 344.468 215.522 343.306 210.508 340.982C205.555 338.659 201.641 335.418 198.767 331.26C195.893 327.04 194.456 322.148 194.456 316.584C194.456 311.142 195.832 306.372 198.584 302.275C201.335 298.117 205.096 294.876 209.865 292.553C214.696 290.229 220.138 289.067 226.192 289.067C232.49 289.067 237.841 290.443 242.244 293.195C246.707 295.885 250.132 299.707 252.517 304.66C254.962 309.613 256.185 315.453 256.185 322.179H209.59V310.897H245.637L239.492 314.841C239.247 312.151 238.575 309.888 237.474 308.054C236.373 306.158 234.906 304.721 233.071 303.743C231.298 302.764 229.158 302.275 226.651 302.275C223.899 302.275 221.545 302.826 219.588 303.926C217.631 305.027 216.103 306.556 215.002 308.512C213.901 310.408 213.351 312.64 213.351 315.208C213.351 318.51 214.085 321.323 215.552 323.647C217.081 325.909 219.282 327.652 222.156 328.875C225.091 330.098 228.669 330.709 232.888 330.709C236.74 330.709 240.562 330.22 244.353 329.242C248.206 328.202 251.691 326.765 254.81 324.931V336.855C251.202 339.301 247.074 341.196 242.427 342.541C237.841 343.826 232.888 344.468 227.568 344.468Z" fill="#1A1311"/>
<path d="M176.251 329.792V320.895L176.893 318.693V305.852L176.251 303.926V290.626H194.963V329.058C194.963 335.479 193.464 340.982 190.468 345.568C187.472 350.154 183.283 353.64 177.902 356.024C172.582 358.409 166.284 359.602 159.007 359.602C154.177 359.602 149.682 358.96 145.524 357.675C141.427 356.453 137.33 354.618 133.233 352.172V338.047C137.024 340.86 140.999 342.878 145.157 344.101C149.376 345.385 153.596 346.027 157.815 346.027C163.807 346.027 168.363 344.743 171.482 342.174C174.661 339.606 176.251 335.479 176.251 329.792ZM153.779 337.496C148.826 337.496 144.423 336.488 140.571 334.47C136.719 332.452 133.661 329.639 131.399 326.031C129.197 322.362 128.097 318.112 128.097 313.282C128.097 308.39 129.197 304.14 131.399 300.532C133.661 296.924 136.719 294.112 140.571 292.094C144.423 290.076 148.826 289.067 153.779 289.067C157.448 289.067 160.811 289.648 163.869 290.81C166.926 291.91 169.647 293.408 172.032 295.304C174.417 297.138 176.404 299.156 177.994 301.358C179.645 303.498 180.898 305.638 181.754 307.778C182.611 309.919 183.039 311.814 183.039 313.465C183.039 315.728 182.366 318.235 181.021 320.986C179.737 323.738 177.841 326.367 175.334 328.875C172.827 331.382 169.739 333.461 166.07 335.112C162.462 336.702 158.365 337.496 153.779 337.496ZM160.108 324.747C163.716 324.747 166.926 323.799 169.739 321.904C172.552 320.008 174.936 317.134 176.893 313.282C174.875 309.368 172.46 306.494 169.647 304.66C166.834 302.764 163.655 301.816 160.108 301.816C157.417 301.816 155.063 302.275 153.045 303.192C151.089 304.109 149.59 305.455 148.551 307.228C147.511 308.94 146.992 310.958 146.992 313.282C146.992 315.544 147.511 317.562 148.551 319.335C149.59 321.048 151.089 322.393 153.045 323.371C155.002 324.288 157.356 324.747 160.108 324.747Z" fill="#1A1311"/>
<path d="M94.5276 344.467C87.8012 344.467 81.931 343.336 76.9168 341.074C71.9026 338.75 67.9891 335.509 65.1763 331.351C62.3634 327.132 60.957 322.301 60.957 316.859C60.957 311.294 62.3634 306.433 65.1763 302.275C67.9891 298.117 71.9026 294.876 76.9168 292.552C81.931 290.229 87.8012 289.067 94.5276 289.067C101.193 289.067 107.032 290.229 112.047 292.552C117.122 294.876 121.066 298.117 123.879 302.275C126.692 306.433 128.098 311.294 128.098 316.859C128.098 322.301 126.692 327.132 123.879 331.351C121.066 335.509 117.122 338.75 112.047 341.074C107.032 343.336 101.193 344.467 94.5276 344.467ZM94.5276 330.617C97.585 330.617 100.184 330.067 102.324 328.966C104.525 327.866 106.207 326.276 107.369 324.197C108.531 322.118 109.111 319.672 109.111 316.859C109.111 313.924 108.531 311.447 107.369 309.429C106.207 307.35 104.525 305.76 102.324 304.66C100.184 303.559 97.585 303.009 94.5276 303.009C91.4701 303.009 88.8407 303.559 86.6394 304.66C84.4381 305.76 82.7565 307.35 81.5947 309.429C80.4328 311.447 79.8519 313.893 79.8519 316.767C79.8519 319.641 80.4328 322.118 81.5947 324.197C82.7565 326.276 84.4381 327.866 86.6394 328.966C88.8407 330.067 91.4701 330.617 94.5276 330.617Z" fill="#1A1311"/>
<path d="M27.9759 282.555H47.0542V343H27.9759V282.555ZM1.74316 274.208H73.2869V291.085H1.74316V274.208Z" fill="#1A1311"/>
<path d="M767.274 265.575L776.722 245.763L777.731 252.642C775.774 252.642 774.001 252.183 772.411 251.266C770.821 250.349 769.537 249.095 768.558 247.505C767.641 245.916 767.183 244.173 767.183 242.277C767.183 240.321 767.641 238.578 768.558 237.049C769.537 235.459 770.821 234.206 772.411 233.288C774.001 232.371 775.774 231.913 777.731 231.913C779.749 231.913 781.553 232.371 783.142 233.288C784.732 234.206 785.986 235.49 786.903 237.141C787.82 238.731 788.279 240.535 788.279 242.552C788.279 244.387 787.82 246.344 786.903 248.423C786.047 250.502 784.549 253.162 782.409 256.403L776.355 265.575H767.274Z" fill="#1A1311"/>
<path d="M761.216 234.94C761.216 238.975 759.993 242.369 757.547 245.121C755.101 247.873 751.616 249.952 747.091 251.358C742.566 252.764 737.124 253.468 730.764 253.468C724.221 253.468 718.473 252.673 713.52 251.083C708.629 249.493 704.807 247.261 702.055 244.387C699.303 241.513 697.836 238.181 697.652 234.389H716.639C717.067 235.796 717.892 237.019 719.115 238.058C720.4 239.037 722.081 239.801 724.16 240.351C726.239 240.841 728.716 241.085 731.59 241.085C735.32 241.085 738.133 240.718 740.028 239.984C741.985 239.251 742.963 238.15 742.963 236.682C742.963 235.521 742.23 234.665 740.762 234.114C739.294 233.503 736.787 233.044 733.241 232.738L723.977 232.005C717.801 231.577 712.878 230.659 709.209 229.253C705.602 227.785 703.003 225.92 701.413 223.658C699.823 221.334 699.028 218.705 699.028 215.77C699.028 211.795 700.251 208.524 702.697 205.955C705.204 203.326 708.659 201.369 713.062 200.085C717.526 198.74 722.723 198.067 728.655 198.067C734.525 198.067 739.753 198.831 744.339 200.36C748.925 201.889 752.594 204.029 755.346 206.781C758.098 209.471 759.687 212.651 760.115 216.32H741.129C740.823 215.158 740.151 214.119 739.111 213.201C738.071 212.223 736.635 211.428 734.8 210.817C732.966 210.205 730.611 209.899 727.737 209.899C724.374 209.899 721.806 210.266 720.033 211C718.32 211.734 717.464 212.773 717.464 214.119C717.464 215.158 717.984 215.984 719.024 216.595C720.124 217.207 722.142 217.635 725.077 217.879L737.277 218.796C743.33 219.163 748.1 220.05 751.585 221.456C755.071 222.802 757.547 224.606 759.015 226.868C760.482 229.131 761.216 231.821 761.216 234.94Z" fill="#1A1311"/>
<path d="M660.532 237.049L655.395 235.307L684.38 199.626H704.1L658.514 252H641.729V181.374H660.532V237.049ZM669.429 226.409L683.554 216.503L705.476 252H684.38L669.429 226.409Z" fill="#1A1311"/>
<path d="M593.14 199.626H612.218L615.337 218.338V252H596.625V217.237L593.14 199.626ZM639.918 198.801V214.761C638.084 214.394 636.341 214.149 634.69 214.027C633.1 213.843 631.633 213.752 630.287 213.752C627.597 213.752 625.12 214.302 622.858 215.403C620.595 216.442 618.761 218.185 617.354 220.631C616.009 223.077 615.337 226.348 615.337 230.445L611.759 226.043C612.249 222.251 612.982 218.705 613.961 215.403C614.939 212.04 616.254 209.074 617.905 206.506C619.617 203.876 621.727 201.828 624.234 200.36C626.741 198.831 629.798 198.067 633.406 198.067C634.445 198.067 635.516 198.128 636.616 198.251C637.717 198.373 638.818 198.556 639.918 198.801Z" fill="#1A1311"/>
<path d="M563.689 253.467C556.962 253.467 551.092 252.336 546.078 250.074C541.064 247.75 537.15 244.509 534.337 240.351C531.525 236.132 530.118 231.301 530.118 225.859C530.118 220.294 531.525 215.433 534.337 211.275C537.15 207.117 541.064 203.876 546.078 201.552C551.092 199.229 556.962 198.067 563.689 198.067C570.354 198.067 576.194 199.229 581.208 201.552C586.283 203.876 590.227 207.117 593.04 211.275C595.853 215.433 597.259 220.294 597.259 225.859C597.259 231.301 595.853 236.132 593.04 240.351C590.227 244.509 586.283 247.75 581.208 250.074C576.194 252.336 570.354 253.467 563.689 253.467ZM563.689 239.617C566.746 239.617 569.345 239.067 571.485 237.966C573.686 236.866 575.368 235.276 576.53 233.197C577.692 231.118 578.273 228.672 578.273 225.859C578.273 222.924 577.692 220.447 576.53 218.429C575.368 216.35 573.686 214.76 571.485 213.66C569.345 212.559 566.746 212.009 563.689 212.009C560.631 212.009 558.002 212.559 555.8 213.66C553.599 214.76 551.918 216.35 550.756 218.429C549.594 220.447 549.013 222.893 549.013 225.767C549.013 228.641 549.594 231.118 550.756 233.197C551.918 235.276 553.599 236.866 555.8 237.966C558.002 239.067 560.631 239.617 563.689 239.617Z" fill="#1A1311"/>
<path d="M510.838 241.452H504.967L519.826 199.626H538.538L518.909 252H499.831L483.412 208.524H489.65L473.048 252H453.969L434.341 199.626H453.236L468.003 241.452H462.133L477.634 199.626H495.52L510.838 241.452Z" fill="#1A1311"/>
<path d="M415.17 253.467C408.81 253.467 403.123 252.306 398.109 249.982C393.156 247.658 389.243 244.417 386.369 240.259C383.495 236.04 382.058 231.148 382.058 225.584C382.058 220.141 383.433 215.372 386.185 211.275C388.937 207.117 392.697 203.876 397.467 201.552C402.298 199.229 407.74 198.067 413.794 198.067C420.092 198.067 425.442 199.443 429.845 202.194C434.309 204.885 437.733 208.707 440.118 213.66C442.564 218.613 443.787 224.452 443.787 231.179H397.192V219.897H433.239L427.093 223.841C426.849 221.15 426.176 218.888 425.076 217.053C423.975 215.158 422.507 213.721 420.673 212.743C418.9 211.764 416.759 211.275 414.252 211.275C411.501 211.275 409.146 211.825 407.19 212.926C405.233 214.027 403.704 215.555 402.604 217.512C401.503 219.408 400.953 221.64 400.953 224.208C400.953 227.51 401.686 230.323 403.154 232.646C404.683 234.909 406.884 236.652 409.758 237.875C412.693 239.098 416.27 239.709 420.489 239.709C424.342 239.709 428.164 239.22 431.955 238.241C435.807 237.202 439.293 235.765 442.411 233.93V245.854C438.803 248.3 434.676 250.196 430.029 251.541C425.442 252.825 420.489 253.467 415.17 253.467Z" fill="#1A1311"/>
<path d="M278.502 199.626H297.305L300.699 220.539V252H281.987V217.696L278.502 199.626ZM318.768 198.067C323.599 198.067 327.696 199.076 331.059 201.094C334.422 203.112 336.99 205.955 338.764 209.624C340.537 213.293 341.424 217.634 341.424 222.649V252H322.712V225.4C322.712 221.487 321.826 218.49 320.052 216.411C318.279 214.332 315.711 213.293 312.348 213.293C309.963 213.293 307.884 213.843 306.11 214.944C304.398 216.045 303.053 217.604 302.075 219.622C301.157 221.64 300.699 224.024 300.699 226.776L294.92 223.749C295.654 218.185 297.152 213.507 299.415 209.716C301.677 205.863 304.459 202.959 307.762 201.002C311.125 199.045 314.794 198.067 318.768 198.067ZM359.401 198.067C364.232 198.067 368.329 199.076 371.692 201.094C375.117 203.112 377.715 205.955 379.489 209.624C381.262 213.293 382.149 217.634 382.149 222.649V252H363.437V225.4C363.437 221.487 362.551 218.49 360.777 216.411C359.004 214.332 356.436 213.293 353.073 213.293C350.688 213.293 348.609 213.843 346.835 214.944C345.123 216.045 343.778 217.604 342.8 219.622C341.882 221.64 341.424 224.024 341.424 226.776L335.645 223.749C336.379 218.185 337.847 213.507 340.048 209.716C342.31 205.863 345.123 202.959 348.486 201.002C351.85 199.045 355.488 198.067 359.401 198.067Z" fill="#1A1311"/>
<path d="M258.949 252L256.564 233.105L259.041 225.859L256.564 218.613L258.949 199.626H278.394L275.092 225.767L278.394 252H258.949ZM263.352 225.859C262.496 231.362 260.784 236.193 258.215 240.351C255.708 244.509 252.498 247.75 248.585 250.074C244.732 252.336 240.33 253.467 235.376 253.467C230.24 253.467 225.715 252.336 221.802 250.074C217.949 247.75 214.922 244.509 212.721 240.351C210.52 236.132 209.419 231.301 209.419 225.859C209.419 220.294 210.52 215.433 212.721 211.275C214.922 207.117 217.949 203.876 221.802 201.552C225.715 199.229 230.24 198.067 235.376 198.067C240.33 198.067 244.732 199.229 248.585 201.552C252.498 203.815 255.739 207.025 258.307 211.183C260.875 215.28 262.557 220.172 263.352 225.859ZM228.406 225.859C228.406 228.427 228.925 230.72 229.965 232.738C231.066 234.756 232.564 236.346 234.459 237.508C236.355 238.608 238.526 239.159 240.972 239.159C243.54 239.159 245.955 238.608 248.218 237.508C250.48 236.346 252.467 234.756 254.18 232.738C255.892 230.72 257.176 228.427 258.032 225.859C257.176 223.23 255.892 220.906 254.18 218.888C252.467 216.87 250.48 215.28 248.218 214.118C245.955 212.957 243.54 212.376 240.972 212.376C238.526 212.376 236.355 212.957 234.459 214.118C232.564 215.28 231.066 216.87 229.965 218.888C228.925 220.906 228.406 223.23 228.406 225.859Z" fill="#1A1311"/>
<path d="M164.286 199.626H183.364L186.483 218.338V252H167.772V217.237L164.286 199.626ZM211.065 198.801V214.76C209.23 214.394 207.488 214.149 205.837 214.027C204.247 213.843 202.779 213.751 201.434 213.751C198.743 213.751 196.267 214.302 194.004 215.402C191.742 216.442 189.907 218.185 188.501 220.631C187.156 223.077 186.483 226.348 186.483 230.445L182.906 226.042C183.395 222.251 184.129 218.705 185.107 215.402C186.086 212.039 187.4 209.074 189.051 206.505C190.763 203.876 192.873 201.828 195.38 200.36C197.887 198.831 200.945 198.067 204.552 198.067C205.592 198.067 206.662 198.128 207.763 198.25C208.863 198.373 209.964 198.556 211.065 198.801Z" fill="#1A1311"/>
<path d="M112.768 211.367H162.665V227.418H112.768V211.367ZM164.408 183.208V199.259H113.41L122.491 190.179V252H103.504V183.208H164.408Z" fill="#1A1311"/>
<path d="M68.884 202.745H50.2643C49.9586 200.482 48.9496 198.617 47.2375 197.15C45.5865 195.621 43.2017 194.857 40.0831 194.857C38.2487 194.857 36.6588 195.101 35.3135 195.591C34.0294 196.08 33.051 196.783 32.3784 197.7C31.7058 198.617 31.3694 199.657 31.3694 200.819C31.3694 201.797 31.6446 202.776 32.195 203.754C32.8064 204.732 33.7848 205.772 35.1301 206.873L87.5038 252H64.1145L20.8213 214.944C18.1919 212.682 15.96 210.297 14.1256 207.79C12.3523 205.221 11.4656 202.47 11.4656 199.535C11.4656 195.988 12.5357 192.9 14.6759 190.271C16.8161 187.58 19.9653 185.501 24.1234 184.034C28.2814 182.505 33.3568 181.74 39.3493 181.74C45.4642 181.74 50.6618 182.658 54.9422 184.492C59.2837 186.327 62.6163 188.834 64.94 192.013C67.3248 195.193 68.6395 198.77 68.884 202.745ZM64.0227 214.027H83.468L61.5462 238.792C58.4888 242.277 55.4314 245.121 52.374 247.322C49.3165 249.462 46.0145 251.022 42.4679 252C38.9213 252.978 34.8855 253.468 30.3605 253.468C24.6125 253.468 19.6595 252.581 15.5014 250.808C11.4045 248.973 8.25532 246.527 6.05397 243.47C3.85263 240.412 2.75195 236.927 2.75195 233.013C2.75195 229.589 3.66918 226.471 5.50364 223.658C7.33809 220.845 10.2121 218.491 14.1256 216.595C18.0391 214.7 23.145 213.446 29.4433 212.834L33.2956 220.172C29.5044 220.967 26.5693 222.282 24.4902 224.116C22.4112 225.89 21.3717 228.03 21.3717 230.537C21.3717 232.188 21.8609 233.717 22.8392 235.123C23.8788 236.468 25.2546 237.538 26.9668 238.333C28.7401 239.128 30.8191 239.526 33.2039 239.526C37.4843 239.526 41.0309 238.7 43.8437 237.049C46.7177 235.398 49.1637 233.289 51.1816 230.72L64.0227 214.027Z" fill="#1A1311"/>
<path d="M595.477 142.939C595.477 146.975 594.254 150.369 591.808 153.121C589.362 155.872 585.877 157.951 581.352 159.358C576.827 160.764 571.384 161.467 565.025 161.467C558.482 161.467 552.734 160.673 547.781 159.083C542.889 157.493 539.067 155.261 536.316 152.387C533.564 149.513 532.097 146.18 531.913 142.389H550.9C551.328 143.796 552.153 145.018 553.376 146.058C554.66 147.036 556.342 147.801 558.421 148.351C560.5 148.84 562.976 149.085 565.85 149.085C569.581 149.085 572.393 148.718 574.289 147.984C576.246 147.25 577.224 146.15 577.224 144.682C577.224 143.52 576.49 142.664 575.023 142.114C573.555 141.502 571.048 141.044 567.501 140.738L558.237 140.004C552.061 139.576 547.139 138.659 543.47 137.253C539.862 135.785 537.264 133.92 535.674 131.658C534.084 129.334 533.289 126.705 533.289 123.769C533.289 119.795 534.512 116.523 536.958 113.955C539.465 111.326 542.92 109.369 547.323 108.085C551.786 106.74 556.984 106.067 562.915 106.067C568.786 106.067 574.014 106.831 578.6 108.36C583.186 109.889 586.855 112.029 589.607 114.781C592.358 117.471 593.948 120.651 594.376 124.32H575.39C575.084 123.158 574.411 122.118 573.372 121.201C572.332 120.223 570.895 119.428 569.061 118.816C567.226 118.205 564.872 117.899 561.998 117.899C558.635 117.899 556.067 118.266 554.293 119C552.581 119.734 551.725 120.773 551.725 122.118C551.725 123.158 552.245 123.983 553.284 124.595C554.385 125.206 556.403 125.634 559.338 125.879L571.537 126.796C577.591 127.163 582.361 128.05 585.846 129.456C589.331 130.801 591.808 132.605 593.276 134.868C594.743 137.13 595.477 139.821 595.477 142.939Z" fill="#1A1311"/>
<path d="M505.462 161.467C499.103 161.467 493.416 160.306 488.402 157.982C483.449 155.658 479.536 152.417 476.662 148.259C473.788 144.04 472.351 139.148 472.351 133.584C472.351 128.141 473.726 123.372 476.478 119.275C479.23 115.117 482.99 111.876 487.76 109.552C492.591 107.229 498.033 106.067 504.087 106.067C510.385 106.067 515.735 107.443 520.138 110.194C524.602 112.885 528.026 116.707 530.411 121.66C532.857 126.613 534.08 132.452 534.08 139.179H487.485V127.897H523.532L517.386 131.841C517.142 129.15 516.469 126.888 515.369 125.053C514.268 123.158 512.8 121.721 510.966 120.743C509.193 119.764 507.052 119.275 504.545 119.275C501.794 119.275 499.439 119.825 497.483 120.926C495.526 122.027 493.997 123.555 492.896 125.512C491.796 127.408 491.245 129.64 491.245 132.208C491.245 135.51 491.979 138.323 493.447 140.646C494.976 142.909 497.177 144.652 500.051 145.875C502.986 147.098 506.563 147.709 510.782 147.709C514.635 147.709 518.457 147.22 522.248 146.241C526.1 145.202 529.586 143.765 532.704 141.93V153.854C529.096 156.3 524.969 158.196 520.322 159.541C515.735 160.825 510.782 161.467 505.462 161.467Z" fill="#1A1311"/>
<path d="M453.88 106.801L463.327 108.635L472.683 106.801V160H453.88V106.801ZM463.235 102.49C459.994 102.49 457.365 101.695 455.347 100.105C453.329 98.4542 452.32 96.2222 452.32 93.4094C452.32 90.6577 453.329 88.4564 455.347 86.8054C457.365 85.1544 459.994 84.3289 463.235 84.3289C466.598 84.3289 469.258 85.1544 471.215 86.8054C473.233 88.4564 474.242 90.6577 474.242 93.4094C474.242 96.2222 473.233 98.4542 471.215 100.105C469.258 101.695 466.598 102.49 463.235 102.49Z" fill="#1A1311"/>
<path d="M405.291 107.626H424.369L427.488 126.338V160H408.776V125.237L405.291 107.626ZM452.07 106.801V122.761C450.235 122.394 448.492 122.149 446.841 122.027C445.252 121.843 443.784 121.752 442.439 121.752C439.748 121.752 437.272 122.302 435.009 123.403C432.747 124.442 430.912 126.185 429.506 128.631C428.161 131.077 427.488 134.348 427.488 138.445L423.911 134.043C424.4 130.251 425.134 126.705 426.112 123.403C427.09 120.04 428.405 117.074 430.056 114.506C431.768 111.876 433.878 109.828 436.385 108.36C438.892 106.831 441.95 106.067 445.557 106.067C446.597 106.067 447.667 106.128 448.768 106.251C449.868 106.373 450.969 106.556 452.07 106.801Z" fill="#1A1311"/>
<path d="M385.738 160L383.354 141.105L385.83 133.859L383.354 126.613L385.738 107.626H405.184L401.882 133.767L405.184 160H385.738ZM390.141 133.859C389.285 139.362 387.573 144.193 385.005 148.351C382.497 152.509 379.287 155.75 375.374 158.074C371.521 160.336 367.119 161.468 362.166 161.468C357.029 161.468 352.504 160.336 348.591 158.074C344.738 155.75 341.711 152.509 339.51 148.351C337.309 144.132 336.208 139.301 336.208 133.859C336.208 128.295 337.309 123.433 339.51 119.275C341.711 115.117 344.738 111.876 348.591 109.553C352.504 107.229 357.029 106.067 362.166 106.067C367.119 106.067 371.521 107.229 375.374 109.553C379.287 111.815 382.528 115.025 385.096 119.183C387.664 123.28 389.346 128.172 390.141 133.859ZM355.195 133.859C355.195 136.427 355.714 138.72 356.754 140.738C357.855 142.756 359.353 144.346 361.248 145.508C363.144 146.609 365.315 147.159 367.761 147.159C370.329 147.159 372.744 146.609 375.007 145.508C377.269 144.346 379.257 142.756 380.969 140.738C382.681 138.72 383.965 136.427 384.821 133.859C383.965 131.23 382.681 128.906 380.969 126.888C379.257 124.87 377.269 123.28 375.007 122.119C372.744 120.957 370.329 120.376 367.761 120.376C365.315 120.376 363.144 120.957 361.248 122.119C359.353 123.28 357.855 124.87 356.754 126.888C355.714 128.906 355.195 131.23 355.195 133.859Z" fill="#1A1311"/>
<path d="M319.589 160L317.112 140.371L320.322 133.951L317.204 125.879L321.606 89.3735H341.052L335.64 132.391L338.942 160H319.589ZM323.624 133.859C322.768 139.362 321.056 144.193 318.488 148.351C315.981 152.509 312.771 155.75 308.857 158.074C305.005 160.336 300.602 161.468 295.649 161.468C290.512 161.468 285.987 160.336 282.074 158.074C278.222 155.75 275.195 152.509 272.993 148.351C270.792 144.132 269.691 139.301 269.691 133.859C269.691 128.295 270.792 123.433 272.993 119.275C275.195 115.117 278.222 111.876 282.074 109.553C285.987 107.229 290.512 106.067 295.649 106.067C300.602 106.067 305.005 107.229 308.857 109.553C312.771 111.815 316.011 115.025 318.58 119.183C321.148 123.28 322.829 128.172 323.624 133.859ZM288.678 133.859C288.678 136.427 289.198 138.72 290.237 140.738C291.338 142.756 292.836 144.346 294.732 145.508C296.627 146.609 298.798 147.159 301.244 147.159C303.812 147.159 306.228 146.609 308.49 145.508C310.753 144.346 312.74 142.756 314.452 140.738C316.164 138.72 317.448 136.427 318.304 133.859C317.448 131.23 316.164 128.906 314.452 126.888C312.74 124.87 310.753 123.28 308.49 122.119C306.228 120.957 303.812 120.376 301.244 120.376C298.798 120.376 296.627 120.957 294.732 122.119C292.836 123.28 291.338 124.87 290.237 126.888C289.198 128.906 288.678 131.23 288.678 133.859Z" fill="#1A1311"/>
<path d="M204.379 107.626H223.182L226.576 128.539V160H207.864V125.696L204.379 107.626ZM245.838 106.067C250.852 106.067 255.132 107.076 258.679 109.094C262.225 111.112 264.916 113.955 266.75 117.624C268.646 121.293 269.594 125.635 269.594 130.649V160H250.882V133.401C250.882 129.487 249.873 126.491 247.855 124.412C245.899 122.333 243.055 121.293 239.325 121.293C236.757 121.293 234.495 121.843 232.538 122.944C230.642 124.045 229.175 125.604 228.135 127.622C227.096 129.64 226.576 132.025 226.576 134.776L220.797 131.749C221.531 126.185 223.09 121.507 225.475 117.716C227.86 113.864 230.795 110.959 234.281 109.002C237.766 107.046 241.618 106.067 245.838 106.067Z" fill="#1A1311"/>
<path d="M167.291 161.468C162.399 161.468 158.21 160.459 154.725 158.441C151.3 156.423 148.671 153.58 146.837 149.911C145.002 146.181 144.085 141.839 144.085 136.886V107.626H162.796V134.226C162.796 138.078 163.744 141.044 165.64 143.123C167.535 145.202 170.257 146.242 173.803 146.242C176.433 146.242 178.634 145.722 180.407 144.682C182.18 143.582 183.556 142.022 184.535 140.005C185.513 137.987 186.002 135.571 186.002 132.758L191.781 135.785C191.108 141.35 189.579 146.058 187.195 149.911C184.871 153.702 181.997 156.576 178.573 158.533C175.148 160.489 171.388 161.468 167.291 161.468ZM189.396 160L186.002 138.996V107.626H204.714V141.931L208.107 160H189.396Z" fill="#1A1311"/>
<path d="M110.608 161.467C103.881 161.467 98.011 160.336 92.9969 158.074C87.9827 155.75 84.0692 152.509 81.2564 148.351C78.4435 144.132 77.0371 139.301 77.0371 133.859C77.0371 128.294 78.4435 123.433 81.2564 119.275C84.0692 115.117 87.9827 111.876 92.9969 109.552C98.011 107.229 103.881 106.067 110.608 106.067C117.273 106.067 123.112 107.229 128.127 109.552C133.202 111.876 137.146 115.117 139.959 119.275C142.772 123.433 144.178 128.294 144.178 133.859C144.178 139.301 142.772 144.132 139.959 148.351C137.146 152.509 133.202 155.75 128.127 158.074C123.112 160.336 117.273 161.467 110.608 161.467ZM110.608 147.617C113.665 147.617 116.264 147.067 118.404 145.966C120.605 144.866 122.287 143.276 123.449 141.197C124.611 139.118 125.192 136.672 125.192 133.859C125.192 130.924 124.611 128.447 123.449 126.429C122.287 124.35 120.605 122.76 118.404 121.66C116.264 120.559 113.665 120.009 110.608 120.009C107.55 120.009 104.921 120.559 102.719 121.66C100.518 122.76 98.8365 124.35 97.6747 126.429C96.5129 128.447 95.932 130.893 95.932 133.767C95.932 136.641 96.5129 139.118 97.6747 141.197C98.8365 143.276 100.518 144.866 102.719 145.966C104.921 147.067 107.55 147.617 110.608 147.617Z" fill="#1A1311"/>
<path d="M53.0163 126.338L54.5756 122.761C59.8955 122.883 64.2676 123.708 67.6919 125.237C71.1774 126.705 73.7762 128.784 75.4884 131.474C77.2005 134.104 78.0566 137.161 78.0566 140.647C78.0566 144.377 77.2005 147.709 75.4884 150.644C73.7762 153.518 71.208 155.811 67.7837 157.524C64.4205 159.175 60.2012 160 55.1259 160H4.31152L9.63144 124.687L4.31152 91.208H53.2915C59.6509 91.208 64.6039 92.7061 68.1506 95.7024C71.6972 98.6375 73.4705 102.734 73.4705 107.993C73.4705 110.867 72.7978 113.619 71.4526 116.248C70.1073 118.878 67.9365 121.11 64.9403 122.944C62.0051 124.717 58.0305 125.849 53.0163 126.338ZM23.1147 155.597L15.7769 146.7H49.9894C52.7411 146.7 54.8813 146.028 56.41 144.682C57.9387 143.337 58.7031 141.472 58.7031 139.087C58.7031 136.702 57.8776 134.807 56.2266 133.4C54.5756 131.933 52.1296 131.199 48.8888 131.199H20.6382V118.174H46.3205C48.5219 118.174 50.3563 117.532 51.8239 116.248C53.3526 114.903 54.117 113.099 54.117 110.837C54.117 109.002 53.4749 107.504 52.1908 106.342C50.9067 105.119 49.0722 104.508 46.6874 104.508H15.8686L23.1147 95.6107L27.7925 124.687L23.1147 155.597Z" fill="#1A1311"/>
<path d="M367.657 69L365.18 49.3714L368.391 42.9508L365.272 34.8792L369.675 -1.62646H389.12L383.708 41.3915L387.01 69H367.657ZM371.693 42.8591C370.837 48.3624 369.125 53.1932 366.556 57.3513C364.049 61.5094 360.839 64.7502 356.925 67.0739C353.073 69.3364 348.67 70.4676 343.717 70.4676C338.581 70.4676 334.056 69.3364 330.142 67.0739C326.29 64.7502 323.263 61.5094 321.062 57.3513C318.86 53.132 317.76 48.3013 317.76 42.8591C317.76 37.2946 318.86 32.4332 321.062 28.2751C323.263 24.1171 326.29 20.8762 330.142 18.5525C334.056 16.2289 338.581 15.0671 343.717 15.0671C348.67 15.0671 353.073 16.2289 356.925 18.5525C360.839 20.815 364.08 24.0253 366.648 28.1834C369.216 32.2804 370.898 37.1723 371.693 42.8591ZM336.746 42.8591C336.746 45.4273 337.266 47.7204 338.306 49.7383C339.406 51.7562 340.904 53.346 342.8 54.5079C344.696 55.6085 346.866 56.1589 349.312 56.1589C351.881 56.1589 354.296 55.6085 356.559 54.5079C358.821 53.346 360.808 51.7562 362.52 49.7383C364.233 47.7204 365.517 45.4273 366.373 42.8591C365.517 40.2297 364.233 37.906 362.52 35.8881C360.808 33.8702 358.821 32.2804 356.559 31.1186C354.296 29.9567 351.881 29.3758 349.312 29.3758C346.866 29.3758 344.696 29.9567 342.8 31.1186C340.904 32.2804 339.406 33.8702 338.306 35.8881C337.266 37.906 336.746 40.2297 336.746 42.8591Z" fill="#1A1311"/>
<path d="M252.447 16.6264H271.25L274.644 37.5392V69.0001H255.933V34.6958L252.447 16.6264ZM293.906 15.0671C298.92 15.0671 303.201 16.0761 306.747 18.094C310.294 20.1119 312.984 22.9553 314.819 26.6242C316.714 30.2931 317.662 34.6347 317.662 39.6488V69.0001H298.951V42.4005C298.951 38.487 297.942 35.4907 295.924 33.4117C293.967 31.3326 291.124 30.2931 287.394 30.2931C284.825 30.2931 282.563 30.8434 280.606 31.9441C278.711 33.0448 277.243 34.6041 276.203 36.622C275.164 38.6399 274.644 41.0247 274.644 43.7764L268.866 40.7495C269.599 35.185 271.159 30.5071 273.543 26.7159C275.928 22.8636 278.863 19.959 282.349 18.0023C285.834 16.0455 289.687 15.0671 293.906 15.0671Z" fill="#1A1311"/>
<path d="M222.996 70.4677C216.27 70.4677 210.4 69.3364 205.386 67.0739C200.371 64.7503 196.458 61.5094 193.645 57.3513C190.832 53.1321 189.426 48.3013 189.426 42.8591C189.426 37.2946 190.832 32.4333 193.645 28.2752C196.458 24.1171 200.371 20.8762 205.386 18.5526C210.4 16.229 216.27 15.0671 222.996 15.0671C229.661 15.0671 235.501 16.229 240.515 18.5526C245.591 20.8762 249.535 24.1171 252.348 28.2752C255.16 32.4333 256.567 37.2946 256.567 42.8591C256.567 48.3013 255.16 53.1321 252.348 57.3513C249.535 61.5094 245.591 64.7503 240.515 67.0739C235.501 69.3364 229.661 70.4677 222.996 70.4677ZM222.996 56.6175C226.054 56.6175 228.653 56.0672 230.793 54.9665C232.994 53.8658 234.676 52.276 235.837 50.1969C236.999 48.1179 237.58 45.672 237.58 42.8591C237.58 39.924 236.999 37.4475 235.837 35.4296C234.676 33.3505 232.994 31.7607 230.793 30.66C228.653 29.5593 226.054 29.009 222.996 29.009C219.939 29.009 217.31 29.5593 215.108 30.66C212.907 31.7607 211.225 33.3505 210.063 35.4296C208.902 37.4475 208.321 39.8934 208.321 42.7674C208.321 45.6414 208.902 48.1179 210.063 50.1969C211.225 52.276 212.907 53.8658 215.108 54.9665C217.31 56.0672 219.939 56.6175 222.996 56.6175Z" fill="#1A1311"/>
<path d="M150.062 85.602C146.209 85.602 142.785 85.1128 139.789 84.1344C136.792 83.2172 133.888 81.7802 131.075 79.8234V66.7988C133.949 68.6333 136.64 69.9479 139.147 70.7429C141.654 71.5378 144.405 71.9353 147.402 71.9353C149.909 71.9353 152.141 71.3849 154.097 70.2843C156.054 69.1836 157.644 67.1657 158.867 64.2306L178.954 16.6265H198.858L173.726 70.0091C171.953 73.8615 169.782 76.9189 167.214 79.1814C164.707 81.505 161.986 83.156 159.05 84.1344C156.115 85.1128 153.119 85.602 150.062 85.602ZM150.704 64.1388L128.415 16.6265H149.053L168.773 64.1388H150.704Z" fill="#1A1311"/>
<path d="M110.149 70.4674C103.79 70.4674 98.1028 69.3056 93.0886 66.982C88.1356 64.6583 84.2221 61.4175 81.3481 57.2594C78.4741 53.0401 77.0371 48.1482 77.0371 42.5837C77.0371 37.1415 78.413 32.3719 81.1646 28.275C83.9163 24.1169 87.677 20.876 92.4465 18.5524C97.2773 16.2287 102.719 15.0669 108.773 15.0669C115.071 15.0669 120.422 16.4427 124.825 19.1944C129.288 21.8849 132.713 25.7067 135.098 30.6598C137.544 35.6128 138.767 41.4525 138.767 48.1788H92.1714V36.8969H128.218L122.073 40.841C121.828 38.1504 121.156 35.888 120.055 34.0535C118.954 32.1579 117.487 30.7209 115.652 29.7425C113.879 28.7642 111.739 28.275 109.232 28.275C106.48 28.275 104.126 28.8253 102.169 29.926C100.212 31.0266 98.6837 32.5554 97.583 34.5121C96.4823 36.4077 95.932 38.6396 95.932 41.2079C95.932 44.5099 96.6658 47.3227 98.1333 49.6464C99.6621 51.9089 101.863 53.6516 104.737 54.8746C107.673 56.0975 111.25 56.709 115.469 56.709C119.321 56.709 123.143 56.2198 126.934 55.2414C130.787 54.2019 134.272 52.7649 137.391 50.9305V62.8544C133.783 65.3004 129.655 67.196 125.008 68.5413C120.422 69.8254 115.469 70.4674 110.149 70.4674Z" fill="#1A1311"/>
<path d="M53.0163 35.3378L54.5756 31.7606C59.8955 31.8829 64.2676 32.7084 67.6919 34.2371C71.1774 35.7047 73.7762 37.7838 75.4884 40.4743C77.2005 43.1037 78.0566 46.1611 78.0566 49.6466C78.0566 53.3766 77.2005 56.7092 75.4884 59.6443C73.7762 62.5183 71.208 64.8114 67.7837 66.5235C64.4205 68.1746 60.2012 69.0001 55.1259 69.0001H4.31152L9.63144 33.6868L4.31152 0.208008H53.2915C59.6509 0.208008 64.6039 1.70615 68.1506 4.70242C71.6972 7.63755 73.4705 11.7345 73.4705 16.9933C73.4705 19.8672 72.7978 22.6189 71.4526 25.2483C70.1073 27.8777 67.9365 30.1096 64.9403 31.9441C62.0051 33.7174 58.0305 34.8486 53.0163 35.3378ZM23.1147 64.5974L15.7769 55.7003H49.9894C52.7411 55.7003 54.8813 55.0276 56.41 53.6824C57.9387 52.3371 58.7031 50.4721 58.7031 48.0873C58.7031 45.7025 57.8776 43.8069 56.2266 42.4005C54.5756 40.9329 52.1296 40.1991 48.8888 40.1991H20.6382V27.1745H46.3205C48.5219 27.1745 50.3563 26.5324 51.8239 25.2483C53.3526 23.9031 54.117 22.0992 54.117 19.8367C54.117 18.0022 53.4749 16.5041 52.1908 15.3423C50.9067 14.1193 49.0722 13.5078 46.6874 13.5078H15.8686L23.1147 4.6107L27.7925 33.6868L23.1147 64.5974Z" fill="#1A1311"/>
</g>
<defs>
<clipPath id="clip0_207_8715">
<rect width="790" height="360" fill="white"/>
</clipPath>
</defs>
</svg>`;

const ABOUT_TEXT_SVG_SP = `
<svg width="324" height="158" viewBox="0 0 324 158" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M197.967 150.639C197.165 150.639 196.426 150.451 195.749 150.075C195.097 149.699 194.583 149.186 194.208 148.534C193.832 147.857 193.644 147.118 193.644 146.316C193.644 145.514 193.832 144.787 194.208 144.135C194.583 143.483 195.097 142.969 195.749 142.594C196.426 142.192 197.165 141.992 197.967 141.992C198.794 141.992 199.534 142.192 200.186 142.594C200.837 142.969 201.351 143.483 201.727 144.135C202.103 144.787 202.291 145.514 202.291 146.316C202.291 147.118 202.103 147.857 201.727 148.534C201.351 149.186 200.837 149.699 200.186 150.075C199.534 150.451 198.794 150.639 197.967 150.639Z" fill="#1A1311"/>
<path d="M175.053 128.795H182.873L184.151 136.465V150.263H176.481V136.014L175.053 128.795ZM194.227 128.457V134.999C193.475 134.848 192.761 134.748 192.084 134.698C191.433 134.623 190.831 134.585 190.28 134.585C189.177 134.585 188.162 134.811 187.234 135.262C186.307 135.688 185.555 136.402 184.978 137.405C184.427 138.408 184.151 139.749 184.151 141.428L182.685 139.623C182.886 138.069 183.186 136.615 183.587 135.262C183.988 133.883 184.527 132.668 185.204 131.615C185.906 130.537 186.771 129.697 187.798 129.096C188.826 128.469 190.079 128.156 191.558 128.156C191.984 128.156 192.423 128.181 192.874 128.231C193.325 128.281 193.776 128.357 194.227 128.457Z" fill="#1A1311"/>
<path d="M163.794 150.865C161.188 150.865 158.857 150.389 156.801 149.436C154.771 148.484 153.167 147.155 151.989 145.451C150.811 143.721 150.222 141.716 150.222 139.435C150.222 137.204 150.786 135.249 151.914 133.57C153.041 131.866 154.583 130.537 156.538 129.585C158.518 128.632 160.749 128.156 163.23 128.156C165.812 128.156 168.005 128.72 169.81 129.848C171.64 130.951 173.043 132.517 174.021 134.548C175.023 136.578 175.525 138.972 175.525 141.729H156.425V137.104H171.201L168.682 138.721C168.582 137.618 168.306 136.691 167.855 135.939C167.404 135.162 166.802 134.573 166.05 134.172C165.323 133.771 164.446 133.57 163.418 133.57C162.29 133.57 161.325 133.796 160.523 134.247C159.721 134.698 159.095 135.325 158.644 136.127C158.192 136.904 157.967 137.819 157.967 138.871C157.967 140.225 158.268 141.378 158.869 142.33C159.496 143.258 160.398 143.972 161.576 144.473C162.779 144.975 164.246 145.225 165.975 145.225C167.554 145.225 169.121 145.025 170.675 144.624C172.254 144.198 173.682 143.609 174.961 142.857V147.744C173.482 148.747 171.79 149.524 169.885 150.075C168.005 150.602 165.975 150.865 163.794 150.865Z" fill="#1A1311"/>
<path d="M124.908 121.313H132.616V150.263H124.908V121.313ZM140.474 128.156C142.554 128.156 144.308 128.57 145.737 129.397C147.191 130.224 148.306 131.389 149.083 132.893C149.86 134.397 150.249 136.177 150.249 138.232V150.263H142.541V139.36C142.541 137.756 142.128 136.528 141.301 135.675C140.499 134.823 139.333 134.397 137.804 134.397C136.751 134.397 135.824 134.623 135.022 135.074C134.245 135.525 133.643 136.164 133.217 136.991C132.816 137.819 132.616 138.796 132.616 139.924L130.209 138.683C130.51 136.402 131.149 134.485 132.127 132.931C133.104 131.352 134.308 130.161 135.736 129.359C137.19 128.557 138.769 128.156 140.474 128.156Z" fill="#1A1311"/>
<path d="M102.629 134.472V131.126L106.99 129.359L110.299 123.005H114.66V128.795H123.683V134.472H114.66V140.789C114.66 142.343 114.973 143.433 115.6 144.06C116.227 144.686 117.367 145 119.021 145C120.074 145 121.002 144.912 121.804 144.736C122.631 144.536 123.383 144.285 124.059 143.985V149.775C123.308 150.05 122.317 150.301 121.089 150.526C119.861 150.752 118.595 150.865 117.292 150.865C114.911 150.865 112.956 150.501 111.427 149.775C109.923 149.048 108.807 148.007 108.081 146.654C107.354 145.275 106.99 143.671 106.99 141.841V134.472H102.629Z" fill="#1A1311"/>
<path d="M92.5668 150.865C89.9601 150.865 87.629 150.389 85.5737 149.436C83.5434 148.484 81.9393 147.155 80.7612 145.451C79.5832 143.721 78.9941 141.716 78.9941 139.435C78.9941 137.204 79.5581 135.249 80.686 133.57C81.814 131.866 83.3554 130.537 85.3105 129.585C87.2906 128.632 89.5214 128.156 92.0029 128.156C94.5845 128.156 96.7777 128.72 98.5824 129.848C100.412 130.951 101.816 132.517 102.793 134.548C103.796 136.578 104.297 138.972 104.297 141.729H85.1977V137.104H99.9735L97.4545 138.721C97.3542 137.618 97.0785 136.691 96.6273 135.939C96.1762 135.162 95.5746 134.573 94.8227 134.172C94.0958 133.771 93.2185 133.57 92.1908 133.57C91.0629 133.57 90.0979 133.796 89.2958 134.247C88.4938 134.698 87.8671 135.325 87.416 136.127C86.9648 136.904 86.7392 137.819 86.7392 138.871C86.7392 140.225 87.04 141.378 87.6416 142.33C88.2682 143.258 89.1705 143.972 90.3486 144.473C91.5517 144.975 93.018 145.225 94.7475 145.225C96.3266 145.225 97.8931 145.025 99.4471 144.624C101.026 144.198 102.455 143.609 103.733 142.857V147.744C102.254 148.747 100.563 149.524 98.6576 150.075C96.7777 150.602 94.7475 150.865 92.5668 150.865Z" fill="#1A1311"/>
<path d="M71.5316 144.849V141.202L71.7948 140.3V135.036L71.5316 134.247V128.795H79.2015V144.548C79.2015 147.18 78.5874 149.436 77.3592 151.316C76.131 153.196 74.4141 154.625 72.2084 155.602C70.0277 156.58 67.446 157.068 64.4633 157.068C62.4832 157.068 60.6409 156.805 58.9365 156.279C57.2571 155.778 55.5778 155.026 53.8984 154.023V148.233C55.4525 149.386 57.0817 150.213 58.7861 150.714C60.5156 151.241 62.2451 151.504 63.9745 151.504C66.4309 151.504 68.2982 150.978 69.5766 149.925C70.8799 148.872 71.5316 147.18 71.5316 144.849ZM62.3202 148.007C60.29 148.007 58.4853 147.594 56.9062 146.767C55.3271 145.94 54.0739 144.787 53.1465 143.308C52.2441 141.804 51.793 140.062 51.793 138.082C51.793 136.077 52.2441 134.335 53.1465 132.856C54.0739 131.377 55.3271 130.224 56.9062 129.397C58.4853 128.57 60.29 128.156 62.3202 128.156C63.8241 128.156 65.2027 128.394 66.456 128.87C67.7092 129.322 68.8246 129.936 69.8021 130.713C70.7797 131.465 71.5943 132.292 72.246 133.194C72.9227 134.071 73.4366 134.949 73.7875 135.826C74.1384 136.703 74.3138 137.48 74.3138 138.157C74.3138 139.084 74.0381 140.112 73.4867 141.24C72.9603 142.368 72.1833 143.446 71.1556 144.473C70.128 145.501 68.8622 146.353 67.3583 147.03C65.8795 147.682 64.2001 148.007 62.3202 148.007ZM64.9145 142.781C66.3933 142.781 67.7092 142.393 68.8622 141.616C70.0152 140.839 70.9927 139.661 71.7948 138.082C70.9677 136.478 69.9776 135.3 68.8246 134.548C67.6716 133.771 66.3682 133.382 64.9145 133.382C63.8116 133.382 62.8466 133.57 62.0195 133.946C61.2174 134.322 60.6033 134.873 60.1772 135.6C59.7511 136.302 59.538 137.129 59.538 138.082C59.538 139.009 59.7511 139.836 60.1772 140.563C60.6033 141.265 61.2174 141.816 62.0195 142.217C62.8215 142.593 63.7865 142.781 64.9145 142.781Z" fill="#1A1311"/>
<path d="M38.0321 150.865C35.275 150.865 32.8688 150.401 30.8134 149.474C28.7581 148.521 27.154 147.193 26.001 145.488C24.848 143.759 24.2715 141.779 24.2715 139.548C24.2715 137.267 24.848 135.274 26.001 133.57C27.154 131.866 28.7581 130.537 30.8134 129.585C32.8688 128.632 35.275 128.156 38.0321 128.156C40.7642 128.156 43.1579 128.632 45.2133 129.585C47.2936 130.537 48.9103 131.866 50.0633 133.57C51.2163 135.274 51.7928 137.267 51.7928 139.548C51.7928 141.779 51.2163 143.759 50.0633 145.488C48.9103 147.193 47.2936 148.521 45.2133 149.474C43.1579 150.401 40.7642 150.865 38.0321 150.865ZM38.0321 145.188C39.2854 145.188 40.3507 144.962 41.2279 144.511C42.1303 144.06 42.8196 143.408 43.2958 142.556C43.772 141.704 44.0101 140.701 44.0101 139.548C44.0101 138.345 43.772 137.33 43.2958 136.503C42.8196 135.65 42.1303 134.999 41.2279 134.548C40.3507 134.096 39.2854 133.871 38.0321 133.871C36.7789 133.871 35.7011 134.096 34.7988 134.548C33.8964 134.999 33.2071 135.65 32.7309 136.503C32.2547 137.33 32.0166 138.332 32.0166 139.51C32.0166 140.688 32.2547 141.704 32.7309 142.556C33.2071 143.408 33.8964 144.06 34.7988 144.511C35.7011 144.962 36.7789 145.188 38.0321 145.188Z" fill="#1A1311"/>
<path d="M10.7529 125.487H18.5731V150.263H10.7529V125.487ZM0 122.065H29.326V128.983H0V122.065Z" fill="#1A1311"/>
<path d="M313.793 115.527L317.666 107.405L318.08 110.225C317.278 110.225 316.551 110.037 315.899 109.661C315.247 109.285 314.721 108.772 314.32 108.12C313.944 107.468 313.756 106.754 313.756 105.977C313.756 105.175 313.944 104.46 314.32 103.834C314.721 103.182 315.247 102.668 315.899 102.292C316.551 101.916 317.278 101.728 318.08 101.728C318.907 101.728 319.646 101.916 320.298 102.292C320.95 102.668 321.463 103.195 321.839 103.871C322.215 104.523 322.403 105.262 322.403 106.09C322.403 106.842 322.215 107.644 321.839 108.496C321.488 109.348 320.874 110.438 319.997 111.767L317.516 115.527H313.793Z" fill="#1A1311"/>
<path d="M311.311 102.969C311.311 104.623 310.81 106.014 309.807 107.142C308.804 108.27 307.376 109.122 305.521 109.699C303.666 110.275 301.435 110.564 298.829 110.564C296.147 110.564 293.79 110.238 291.76 109.586C289.755 108.934 288.188 108.019 287.061 106.841C285.933 105.663 285.331 104.297 285.256 102.743H293.039C293.214 103.32 293.552 103.821 294.054 104.247C294.58 104.648 295.269 104.962 296.122 105.187C296.974 105.388 297.989 105.488 299.167 105.488C300.696 105.488 301.849 105.338 302.626 105.037C303.428 104.736 303.829 104.285 303.829 103.683C303.829 103.207 303.528 102.856 302.927 102.631C302.325 102.38 301.297 102.192 299.844 102.067L296.046 101.766C293.515 101.59 291.497 101.214 289.993 100.638C288.514 100.036 287.449 99.2718 286.797 98.3444C286.146 97.392 285.82 96.3142 285.82 95.111C285.82 93.4818 286.321 92.1408 287.324 91.0881C288.351 90.0103 289.768 89.2082 291.572 88.6819C293.402 88.1305 295.532 87.8547 297.964 87.8547C300.37 87.8547 302.513 88.168 304.393 88.7947C306.273 89.4213 307.777 90.2986 308.905 91.4265C310.033 92.5293 310.684 93.8327 310.86 95.3366H303.077C302.952 94.8604 302.676 94.4343 302.25 94.0583C301.824 93.6573 301.235 93.3314 300.483 93.0808C299.731 92.8301 298.766 92.7048 297.588 92.7048C296.209 92.7048 295.157 92.8552 294.43 93.156C293.728 93.4568 293.377 93.8829 293.377 94.4343C293.377 94.8604 293.59 95.1988 294.016 95.4494C294.467 95.7001 295.294 95.8755 296.497 95.9758L301.498 96.3518C303.979 96.5021 305.934 96.8656 307.363 97.4421C308.792 97.9935 309.807 98.7329 310.409 99.6603C311.01 100.588 311.311 101.691 311.311 102.969Z" fill="#1A1311"/>
<path d="M270.04 103.834L267.934 103.12L279.815 88.4941H287.898L269.212 109.962H262.332V81.0122H270.04V103.834ZM273.686 99.4726L279.476 95.412L288.462 109.962H279.815L273.686 99.4726Z" fill="#1A1311"/>
<path d="M242.416 88.4939H250.236L251.515 96.1638V109.962H243.845V95.7126L242.416 88.4939ZM261.591 88.1555V94.6975C260.839 94.5471 260.124 94.4468 259.448 94.3967C258.796 94.3215 258.194 94.2839 257.643 94.2839C256.54 94.2839 255.525 94.5095 254.598 94.9607C253.67 95.3868 252.918 96.1011 252.342 97.1037C251.79 98.1063 251.515 99.4473 251.515 101.127L250.048 99.322C250.249 97.7679 250.55 96.3142 250.951 94.9607C251.352 93.5821 251.891 92.3664 252.567 91.3137C253.269 90.2359 254.134 89.3962 255.162 88.7947C256.189 88.168 257.442 87.8547 258.921 87.8547C259.347 87.8547 259.786 87.8798 260.237 87.9299C260.688 87.9801 261.14 88.0553 261.591 88.1555Z" fill="#1A1311"/>
<path d="M230.344 110.564C227.587 110.564 225.18 110.1 223.125 109.172C221.07 108.22 219.465 106.892 218.312 105.187C217.159 103.458 216.583 101.478 216.583 99.2468C216.583 96.9659 217.159 94.9732 218.312 93.2688C219.465 91.5643 221.07 90.2359 223.125 89.2834C225.18 88.331 227.587 87.8547 230.344 87.8547C233.076 87.8547 235.469 88.331 237.525 89.2834C239.605 90.2359 241.222 91.5643 242.375 93.2688C243.528 94.9732 244.104 96.9659 244.104 99.2468C244.104 101.478 243.528 103.458 242.375 105.187C241.222 106.892 239.605 108.22 237.525 109.172C235.469 110.1 233.076 110.564 230.344 110.564ZM230.344 104.886C231.597 104.886 232.662 104.661 233.539 104.21C234.442 103.758 235.131 103.107 235.607 102.255C236.084 101.402 236.322 100.4 236.322 99.2468C236.322 98.0436 236.084 97.0285 235.607 96.2014C235.131 95.3492 234.442 94.6975 233.539 94.2463C232.662 93.7951 231.597 93.5695 230.344 93.5695C229.09 93.5695 228.013 93.7951 227.11 94.2463C226.208 94.6975 225.519 95.3492 225.042 96.2014C224.566 97.0285 224.328 98.0311 224.328 99.2092C224.328 100.387 224.566 101.402 225.042 102.255C225.519 103.107 226.208 103.758 227.11 104.21C228.013 104.661 229.09 104.886 230.344 104.886Z" fill="#1A1311"/>
<path d="M208.68 105.639H206.273L212.364 88.4941H220.034L211.988 109.962H204.168L197.438 92.1411H199.994L193.189 109.962H185.369L177.323 88.4941H185.068L191.122 105.639H188.715L195.069 88.4941H202.401L208.68 105.639Z" fill="#1A1311"/>
<path d="M169.465 110.564C166.858 110.564 164.527 110.087 162.472 109.135C160.442 108.182 158.838 106.854 157.66 105.15C156.482 103.42 155.893 101.415 155.893 99.134C155.893 96.9032 156.457 94.9481 157.584 93.2688C158.712 91.5643 160.254 90.2359 162.209 89.2834C164.189 88.331 166.42 87.8547 168.901 87.8547C171.483 87.8547 173.676 88.4187 175.481 89.5466C177.311 90.6495 178.714 92.216 179.692 94.2463C180.694 96.2766 181.196 98.6703 181.196 101.427H162.096V96.8029H176.872L174.353 98.4196C174.253 97.3168 173.977 96.3894 173.526 95.6374C173.075 94.8604 172.473 94.2714 171.721 93.8703C170.994 93.4693 170.117 93.2688 169.089 93.2688C167.961 93.2688 166.996 93.4944 166.194 93.9455C165.392 94.3967 164.766 95.0233 164.314 95.8254C163.863 96.6024 163.638 97.5173 163.638 98.57C163.638 99.9235 163.938 101.077 164.54 102.029C165.167 102.956 166.069 103.671 167.247 104.172C168.45 104.673 169.916 104.924 171.646 104.924C173.225 104.924 174.792 104.723 176.346 104.322C177.925 103.896 179.353 103.307 180.632 102.555V107.443C179.153 108.446 177.461 109.223 175.556 109.774C173.676 110.3 171.646 110.564 169.465 110.564Z" fill="#1A1311"/>
<path d="M113.444 88.4939H121.152L122.543 97.0661V109.962H114.873V95.9006L113.444 88.4939ZM129.95 87.8547C131.93 87.8547 133.609 88.2683 134.988 89.0955C136.366 89.9226 137.419 91.0881 138.146 92.592C138.873 94.0959 139.236 95.8755 139.236 97.9309V109.962H131.566V99.0588C131.566 97.4546 131.203 96.2264 130.476 95.3742C129.749 94.522 128.696 94.0959 127.318 94.0959C126.34 94.0959 125.488 94.3215 124.761 94.7727C124.059 95.2238 123.508 95.863 123.107 96.6901C122.731 97.5173 122.543 98.4948 122.543 99.6227L120.174 98.382C120.475 96.1011 121.089 94.1836 122.017 92.6296C122.944 91.0505 124.084 89.8599 125.438 89.0579C126.816 88.2558 128.32 87.8547 129.95 87.8547ZM146.605 87.8547C148.585 87.8547 150.265 88.2683 151.643 89.0955C153.047 89.9226 154.112 91.0881 154.839 92.592C155.566 94.0959 155.929 95.8755 155.929 97.9309V109.962H148.26V99.0588C148.26 97.4546 147.896 96.2264 147.169 95.3742C146.442 94.522 145.39 94.0959 144.011 94.0959C143.034 94.0959 142.181 94.3215 141.454 94.7727C140.753 95.2238 140.201 95.863 139.8 96.6901C139.424 97.5173 139.236 98.4948 139.236 99.6227L136.868 98.382C137.168 96.1011 137.77 94.1836 138.672 92.6296C139.6 91.0505 140.753 89.8599 142.131 89.0579C143.51 88.2558 145.001 87.8547 146.605 87.8547Z" fill="#1A1311"/>
<path d="M105.43 109.962L104.452 102.217L105.467 99.2468L104.452 96.2766L105.43 88.4939H113.4L112.047 99.2092L113.4 109.962H105.43ZM107.234 99.2468C106.883 101.503 106.182 103.483 105.129 105.187C104.101 106.892 102.785 108.22 101.181 109.172C99.602 110.1 97.7973 110.564 95.767 110.564C93.6616 110.564 91.8068 110.1 90.2026 109.172C88.6235 108.22 87.3828 106.892 86.4805 105.187C85.5781 103.458 85.127 101.478 85.127 99.2468C85.127 96.9659 85.5781 94.9732 86.4805 93.2688C87.3828 91.5643 88.6235 90.2359 90.2026 89.2834C91.8068 88.331 93.6616 87.8547 95.767 87.8547C97.7973 87.8547 99.602 88.331 101.181 89.2834C102.785 90.2108 104.114 91.5268 105.166 93.2312C106.219 94.9105 106.908 96.9157 107.234 99.2468ZM92.9096 99.2468C92.9096 100.299 93.1227 101.239 93.5488 102.067C93.9999 102.894 94.614 103.545 95.3911 104.022C96.1681 104.473 97.0579 104.698 98.0605 104.698C99.1132 104.698 100.103 104.473 101.031 104.022C101.958 103.545 102.773 102.894 103.475 102.067C104.176 101.239 104.703 100.299 105.054 99.2468C104.703 98.169 104.176 97.2165 103.475 96.3894C102.773 95.5622 101.958 94.9105 101.031 94.4343C100.103 93.9581 99.1132 93.7199 98.0605 93.7199C97.0579 93.7199 96.1681 93.9581 95.3911 94.4343C94.614 94.9105 93.9999 95.5622 93.5488 96.3894C93.1227 97.2165 92.9096 98.169 92.9096 99.2468Z" fill="#1A1311"/>
<path d="M66.627 88.4939H74.4472L75.7255 96.1638V109.962H68.0557V95.7126L66.627 88.4939ZM85.8016 88.1555V94.6975C85.0497 94.5471 84.3353 94.4468 83.6586 94.3967C83.0069 94.3215 82.4053 94.2839 81.8539 94.2839C80.7511 94.2839 79.7359 94.5095 78.8085 94.9607C77.8811 95.3868 77.1292 96.1011 76.5527 97.1037C76.0012 98.1063 75.7255 99.4473 75.7255 101.127L74.2592 99.322C74.4598 97.7679 74.7605 96.3142 75.1616 94.9607C75.5626 93.5821 76.1015 92.3664 76.7783 91.3137C77.4801 90.2359 78.3448 89.3962 79.3725 88.7947C80.4001 88.168 81.6534 87.8547 83.1322 87.8547C83.5583 87.8547 83.997 87.8798 84.4481 87.9299C84.8993 87.9801 85.3505 88.0553 85.8016 88.1555Z" fill="#1A1311"/>
<path d="M45.5102 93.3066H65.9632V99.8861H45.5102V93.3066ZM66.6776 81.7642V88.3437H45.7734L49.4956 84.6216V109.962H41.7129V81.7642H66.6776Z" fill="#1A1311"/>
<path d="M27.5218 89.7724H19.8895C19.7642 88.845 19.3506 88.0805 18.6488 87.479C17.9721 86.8523 16.9945 86.539 15.7162 86.539C14.9643 86.539 14.3126 86.6393 13.7612 86.8398C13.2348 87.0403 12.8338 87.3286 12.558 87.7045C12.2823 88.0805 12.1445 88.5066 12.1445 88.9829C12.1445 89.3839 12.2573 89.7849 12.4828 90.186C12.7335 90.587 13.1345 91.0131 13.686 91.4643L35.1541 109.962H25.5668L7.82076 94.7729C6.74297 93.8455 5.82809 92.8679 5.07615 91.8403C4.34926 90.7875 3.98582 89.6596 3.98582 88.4565C3.98582 87.0027 4.42446 85.737 5.30173 84.6592C6.179 83.5563 7.46985 82.7041 9.17427 82.1025C10.8787 81.4759 12.9591 81.1626 15.4154 81.1626C17.9219 81.1626 20.0525 81.5386 21.807 82.2905C23.5866 83.0425 24.9527 84.0701 25.9051 85.3735C26.8827 86.6769 27.4216 88.1432 27.5218 89.7724ZM25.5292 94.3969H33.4998L24.514 104.548C23.2608 105.977 22.0075 107.142 20.7543 108.045C19.501 108.922 18.1475 109.561 16.6938 109.962C15.24 110.363 13.5857 110.564 11.7309 110.564C9.37479 110.564 7.34452 110.2 5.64011 109.473C3.96075 108.722 2.66991 107.719 1.76757 106.466C0.865232 105.212 0.414062 103.784 0.414062 102.18C0.414062 100.776 0.790037 99.4976 1.54199 98.3446C2.29393 97.1916 3.47199 96.2266 5.07615 95.4496C6.6803 94.6726 8.77323 94.1588 11.3549 93.9081L12.934 96.9159C11.38 97.2418 10.1769 97.7807 9.32466 98.5326C8.47245 99.2595 8.04634 100.137 8.04634 101.164C8.04634 101.841 8.24686 102.468 8.6479 103.044C9.07401 103.596 9.63797 104.034 10.3398 104.36C11.0667 104.686 11.9189 104.849 12.8964 104.849C14.651 104.849 16.1047 104.511 17.2577 103.834C18.4358 103.157 19.4384 102.292 20.2655 101.24L25.5292 94.3969Z" fill="#1A1311"/>
<path d="M243.373 62.258C243.373 63.9123 242.872 65.3034 241.869 66.4313C240.867 67.5592 239.438 68.4114 237.583 68.9879C235.729 69.5644 233.498 69.8527 230.891 69.8527C228.209 69.8527 225.853 69.5268 223.823 68.8751C221.818 68.2234 220.251 67.3086 219.123 66.1305C217.995 64.9525 217.394 63.5864 217.318 62.0324H225.101C225.276 62.6089 225.615 63.1102 226.116 63.5363C226.643 63.9373 227.332 64.2506 228.184 64.4762C229.036 64.6767 230.051 64.777 231.229 64.777C232.758 64.777 233.911 64.6266 234.688 64.3258C235.49 64.025 235.891 63.5739 235.891 62.9723C235.891 62.4961 235.591 62.1452 234.989 61.9196C234.388 61.6689 233.36 61.481 231.906 61.3556L228.109 61.0549C225.577 60.8794 223.56 60.5034 222.056 59.9269C220.577 59.3254 219.512 58.5609 218.86 57.6335C218.208 56.681 217.882 55.6032 217.882 54.4001C217.882 52.7709 218.384 51.4299 219.386 50.3772C220.414 49.2994 221.83 48.4973 223.635 47.9709C225.464 47.4195 227.595 47.1438 230.026 47.1438C232.433 47.1438 234.576 47.4571 236.455 48.0837C238.335 48.7104 239.839 49.5876 240.967 50.7156C242.095 51.8184 242.747 53.1218 242.922 54.6257H235.14C235.014 54.1495 234.739 53.7234 234.312 53.3474C233.886 52.9463 233.297 52.6205 232.545 52.3698C231.793 52.1192 230.828 51.9939 229.65 51.9939C228.272 51.9939 227.219 52.1443 226.492 52.445C225.79 52.7458 225.439 53.1719 225.439 53.7233C225.439 54.1495 225.652 54.4878 226.079 54.7385C226.53 54.9891 227.357 55.1646 228.56 55.2648L233.56 55.6408C236.042 55.7912 237.997 56.1547 239.426 56.7311C240.854 57.2826 241.869 58.022 242.471 58.9494C243.073 59.8768 243.373 60.9797 243.373 62.258Z" fill="#1A1311"/>
<path d="M206.476 69.8527C203.869 69.8527 201.538 69.3764 199.483 68.424C197.453 67.4715 195.848 66.143 194.67 64.4386C193.492 62.7091 192.903 60.7039 192.903 58.423C192.903 56.1922 193.467 54.2372 194.595 52.5578C195.723 50.8534 197.265 49.525 199.22 48.5725C201.2 47.62 203.431 47.1438 205.912 47.1438C208.494 47.1438 210.687 47.7078 212.492 48.8357C214.321 49.9385 215.725 51.5051 216.703 53.5354C217.705 55.5656 218.206 57.9593 218.206 60.7165H199.107V56.092H213.883L211.364 57.7087C211.263 56.6058 210.988 55.6784 210.537 54.9265C210.085 54.1495 209.484 53.5604 208.732 53.1594C208.005 52.7583 207.128 52.5578 206.1 52.5578C204.972 52.5578 204.007 52.7834 203.205 53.2346C202.403 53.6858 201.776 54.3124 201.325 55.1145C200.874 55.8915 200.648 56.8063 200.648 57.8591C200.648 59.2126 200.949 60.3656 201.551 61.318C202.177 62.2454 203.08 62.9598 204.258 63.4611C205.461 63.9624 206.927 64.213 208.657 64.213C210.236 64.213 211.802 64.0125 213.356 63.6115C214.935 63.1854 216.364 62.5963 217.642 61.8444V66.7321C216.164 67.7347 214.472 68.5117 212.567 69.0631C210.687 69.5895 208.657 69.8527 206.476 69.8527Z" fill="#1A1311"/>
<path d="M185.333 47.4445L189.205 48.1965L193.04 47.4445V69.251H185.333V47.4445ZM189.167 45.6774C187.839 45.6774 186.761 45.3516 185.934 44.6999C185.107 44.0232 184.693 43.1083 184.693 41.9553C184.693 40.8274 185.107 39.925 185.934 39.2483C186.761 38.5715 187.839 38.2332 189.167 38.2332C190.546 38.2332 191.636 38.5715 192.438 39.2483C193.266 39.925 193.679 40.8274 193.679 41.9553C193.679 43.1083 193.266 44.0232 192.438 44.6999C191.636 45.3516 190.546 45.6774 189.167 45.6774Z" fill="#1A1311"/>
<path d="M165.416 47.783H173.236L174.515 55.4528V69.2511H166.845V55.0017L165.416 47.783ZM184.591 47.4446V53.9865C183.839 53.8361 183.124 53.7359 182.448 53.6858C181.796 53.6106 181.194 53.573 180.643 53.573C179.54 53.573 178.525 53.7985 177.598 54.2497C176.67 54.6758 175.918 55.3902 175.342 56.3928C174.79 57.3954 174.515 58.7363 174.515 60.4157L173.048 58.611C173.249 57.057 173.55 55.6032 173.951 54.2497C174.352 52.8711 174.891 51.6555 175.567 50.6028C176.269 49.525 177.134 48.6853 178.162 48.0837C179.189 47.4571 180.442 47.1438 181.921 47.1438C182.347 47.1438 182.786 47.1689 183.237 47.219C183.688 47.2691 184.14 47.3443 184.591 47.4446Z" fill="#1A1311"/>
<path d="M157.401 69.2511L156.424 61.506L157.439 58.5358L156.424 55.5656L157.401 47.783H165.372L164.018 58.4982L165.372 69.2511H157.401ZM159.206 58.5358C158.855 60.7917 158.153 62.7718 157.1 64.4762C156.073 66.1806 154.757 67.5091 153.153 68.4615C151.574 69.389 149.769 69.8527 147.739 69.8527C145.633 69.8527 143.778 69.389 142.174 68.4615C140.595 67.5091 139.354 66.1806 138.452 64.4762C137.55 62.7467 137.099 60.7666 137.099 58.5358C137.099 56.2549 137.55 54.2622 138.452 52.5578C139.354 50.8534 140.595 49.525 142.174 48.5725C143.778 47.62 145.633 47.1438 147.739 47.1438C149.769 47.1438 151.574 47.62 153.153 48.5725C154.757 49.4999 156.085 50.8158 157.138 52.5202C158.191 54.1996 158.88 56.2048 159.206 58.5358ZM144.881 58.5358C144.881 59.5886 145.094 60.5285 145.52 61.3556C145.972 62.1828 146.586 62.8345 147.363 63.3107C148.14 63.7619 149.03 63.9875 150.032 63.9875C151.085 63.9875 152.075 63.7619 153.002 63.3107C153.93 62.8345 154.744 62.1828 155.446 61.3556C156.148 60.5285 156.674 59.5886 157.025 58.5358C156.674 57.458 156.148 56.5056 155.446 55.6784C154.744 54.8513 153.93 54.1996 153.002 53.7233C152.075 53.2471 151.085 53.009 150.032 53.009C149.03 53.009 148.14 53.2471 147.363 53.7233C146.586 54.1996 145.972 54.8513 145.52 55.6784C145.094 56.5056 144.881 57.458 144.881 58.5358Z" fill="#1A1311"/>
<path d="M130.286 69.2511L129.271 61.2052L130.587 58.5734L129.308 55.2648L131.113 40.301H139.084L136.866 57.9342L138.219 69.2511H130.286ZM131.94 58.5358C131.589 60.7916 130.888 62.7718 129.835 64.4762C128.807 66.1806 127.491 67.509 125.887 68.4615C124.308 69.3889 122.503 69.8526 120.473 69.8526C118.368 69.8526 116.513 69.3889 114.909 68.4615C113.33 67.509 112.089 66.1806 111.187 64.4762C110.284 62.7467 109.833 60.7666 109.833 58.5358C109.833 56.2549 110.284 54.2622 111.187 52.5578C112.089 50.8534 113.33 49.5249 114.909 48.5725C116.513 47.62 118.368 47.1438 120.473 47.1438C122.503 47.1438 124.308 47.62 125.887 48.5725C127.491 49.4999 128.82 50.8158 129.872 52.5202C130.925 54.1995 131.614 56.2047 131.94 58.5358ZM117.616 58.5358C117.616 59.5885 117.829 60.5284 118.255 61.3556C118.706 62.1827 119.32 62.8344 120.097 63.3107C120.874 63.7618 121.764 63.9874 122.767 63.9874C123.819 63.9874 124.809 63.7618 125.737 63.3107C126.664 62.8344 127.479 62.1827 128.181 61.3556C128.882 60.5284 129.409 59.5885 129.76 58.5358C129.409 57.458 128.882 56.5055 128.181 55.6784C127.479 54.8512 126.664 54.1995 125.737 53.7233C124.809 53.2471 123.819 53.009 122.767 53.009C121.764 53.009 120.874 53.2471 120.097 53.7233C119.32 54.1995 118.706 54.8512 118.255 55.6784C117.829 56.5055 117.616 57.458 117.616 58.5358Z" fill="#1A1311"/>
<path d="M83 48.2607H90.7075L92.0986 56.833V69.7289H84.4287V55.6674L83 48.2607ZM99.994 47.6216C102.049 47.6216 103.804 48.0352 105.258 48.8623C106.711 49.6894 107.814 50.855 108.566 52.3589C109.343 53.8628 109.732 55.6424 109.732 57.6977V69.7289H102.062V58.8256C102.062 57.2215 101.648 55.9933 100.821 55.1411C100.019 54.2889 98.8536 53.8628 97.3246 53.8628C96.2719 53.8628 95.3445 54.0883 94.5424 54.5395C93.7654 54.9907 93.1638 55.6298 92.7377 56.457C92.3116 57.2841 92.0986 58.2617 92.0986 59.3896L89.7299 58.1489C90.0307 55.868 90.6699 53.9505 91.6474 52.3965C92.6249 50.8174 93.8281 49.6268 95.2568 48.8247C96.6855 48.0226 98.2646 47.6216 99.994 47.6216Z" fill="#1A1311"/>
<path d="M67.8588 69.8527C65.8536 69.8527 64.1367 69.4391 62.708 68.6119C61.3043 67.7848 60.2266 66.6193 59.4746 65.1154C58.7227 63.5864 58.3467 61.8068 58.3467 59.7765V47.783H66.0166V58.6862C66.0166 60.2653 66.4051 61.481 67.1821 62.3332C67.9591 63.1854 69.0745 63.6115 70.5283 63.6115C71.606 63.6115 72.5084 63.3984 73.2353 62.9723C73.9622 62.5212 74.5261 61.882 74.9271 61.0549C75.3282 60.2277 75.5287 59.2376 75.5287 58.0847L77.8973 59.3254C77.6216 61.6063 76.995 63.5363 76.0175 65.1154C75.065 66.6694 73.887 67.8475 72.4833 68.6495C71.0797 69.4516 69.5382 69.8527 67.8588 69.8527ZM76.9198 69.2511L75.5287 60.6413V47.783H83.1986V61.8444L84.5897 69.2511H76.9198Z" fill="#1A1311"/>
<path d="M44.6239 69.8527C41.8668 69.8527 39.4606 69.389 37.4052 68.4615C35.3499 67.5091 33.7458 66.1806 32.5928 64.4762C31.4398 62.7467 30.8633 60.7666 30.8633 58.5358C30.8633 56.2549 31.4398 54.2622 32.5928 52.5578C33.7458 50.8534 35.3499 49.525 37.4052 48.5725C39.4606 47.62 41.8668 47.1438 44.6239 47.1438C47.356 47.1438 49.7497 47.62 51.8051 48.5725C53.8854 49.525 55.5021 50.8534 56.6551 52.5578C57.8081 54.2622 58.3846 56.2549 58.3846 58.5358C58.3846 60.7666 57.8081 62.7467 56.6551 64.4762C55.5021 66.1806 53.8854 67.5091 51.8051 68.4615C49.7497 69.389 47.356 69.8527 44.6239 69.8527ZM44.6239 64.1754C45.8772 64.1754 46.9425 63.9499 47.8197 63.4987C48.7221 63.0475 49.4114 62.3958 49.8876 61.5436C50.3638 60.6914 50.6019 59.6888 50.6019 58.5358C50.6019 57.3327 50.3638 56.3176 49.8876 55.4904C49.4114 54.6382 48.7221 53.9865 47.8197 53.5354C46.9425 53.0842 45.8772 52.8586 44.6239 52.8586C43.3707 52.8586 42.2929 53.0842 41.3906 53.5354C40.4882 53.9865 39.7989 54.6382 39.3227 55.4904C38.8465 56.3176 38.6084 57.3202 38.6084 58.4982C38.6084 59.6763 38.8465 60.6914 39.3227 61.5436C39.7989 62.3958 40.4882 63.0475 41.3906 63.4987C42.2929 63.9499 43.3707 64.1754 44.6239 64.1754Z" fill="#1A1311"/>
<path d="M21.017 55.4528L21.6561 53.9865C23.8368 54.0366 25.6289 54.375 27.0326 55.0016C28.4613 55.6032 29.5265 56.4554 30.2283 57.5583C30.9302 58.636 31.2811 59.8893 31.2811 61.318C31.2811 62.847 30.9302 64.213 30.2283 65.4161C29.5265 66.5942 28.4738 67.5341 27.0702 68.2359C25.6916 68.9127 23.9621 69.2511 21.8817 69.2511H1.05273L3.23339 54.776L1.05273 41.053H21.1298C23.7365 41.053 25.7668 41.6671 27.2206 42.8953C28.6743 44.0984 29.4012 45.7777 29.4012 47.9333C29.4012 49.1114 29.1255 50.2393 28.5741 51.3171C28.0226 52.3949 27.1328 53.3097 25.9046 54.0617C24.7015 54.7886 23.0723 55.2523 21.017 55.4528ZM8.76021 67.4464L5.75241 63.7994H19.7763C20.9042 63.7994 21.7815 63.5237 22.4081 62.9723C23.0347 62.4209 23.348 61.6564 23.348 60.6788C23.348 59.7013 23.0096 58.9243 22.3329 58.3478C21.6561 57.7462 20.6535 57.4455 19.3251 57.4455H7.74508V52.1066H18.2724C19.1747 52.1066 19.9266 51.8434 20.5282 51.3171C21.1548 50.7657 21.4681 50.0262 21.4681 49.0988C21.4681 48.3469 21.205 47.7328 20.6786 47.2566C20.1522 46.7553 19.4003 46.5046 18.4228 46.5046H5.79001L8.76021 42.8577L10.6777 54.776L8.76021 67.4464Z" fill="#1A1311"/>
<path d="M149.989 28.95L148.974 20.9042L150.29 18.2724L149.012 14.9638L150.816 0H158.787L156.569 17.6332L157.922 28.95H149.989ZM151.643 18.2348C151.293 20.4906 150.591 22.4707 149.538 24.1752C148.51 25.8796 147.194 27.208 145.59 28.1605C144.011 29.0879 142.206 29.5516 140.176 29.5516C138.071 29.5516 136.216 29.0879 134.612 28.1605C133.033 27.208 131.792 25.8796 130.89 24.1752C129.987 22.4457 129.536 20.4655 129.536 18.2348C129.536 15.9538 129.987 13.9612 130.89 12.2568C131.792 10.5523 133.033 9.22391 134.612 8.27144C136.216 7.31897 138.071 6.84274 140.176 6.84274C142.206 6.84274 144.011 7.31897 145.59 8.27144C147.194 9.19884 148.523 10.5148 149.576 12.2192C150.628 13.8985 151.318 15.9037 151.643 18.2348ZM137.319 18.2348C137.319 19.2875 137.532 20.2274 137.958 21.0546C138.409 21.8817 139.023 22.5334 139.8 23.0096C140.577 23.4608 141.467 23.6864 142.47 23.6864C143.522 23.6864 144.512 23.4608 145.44 23.0096C146.367 22.5334 147.182 21.8817 147.884 21.0546C148.586 20.2274 149.112 19.2875 149.463 18.2348C149.112 17.157 148.586 16.2045 147.884 15.3774C147.182 14.5502 146.367 13.8985 145.44 13.4223C144.512 12.9461 143.522 12.7079 142.47 12.7079C141.467 12.7079 140.577 12.9461 139.8 13.4223C139.023 13.8985 138.409 14.5502 137.958 15.3774C137.532 16.2045 137.319 17.157 137.319 18.2348Z" fill="#1A1311"/>
<path d="M102.764 7.48168H110.471L111.862 16.0539V28.9498H104.192V14.8884L102.764 7.48168ZM119.758 6.84253C121.813 6.84253 123.568 7.2561 125.021 8.08324C126.475 8.91039 127.578 10.0759 128.33 11.5798C129.107 13.0837 129.495 14.8633 129.495 16.9186V28.9498H121.826V18.0466C121.826 16.4424 121.412 15.2142 120.585 14.362C119.783 13.5098 118.617 13.0837 117.088 13.0837C116.036 13.0837 115.108 13.3093 114.306 13.7605C113.529 14.2116 112.928 14.8508 112.501 15.6779C112.075 16.5051 111.862 17.4826 111.862 18.6105L109.494 17.3698C109.794 15.0889 110.434 13.1714 111.411 11.6174C112.389 10.0383 113.592 8.84772 115.02 8.04565C116.449 7.24357 118.028 6.84253 119.758 6.84253Z" fill="#1A1311"/>
<path d="M90.6923 29.5514C87.9352 29.5514 85.5289 29.0877 83.4736 28.1603C81.4183 27.2078 79.8141 25.8794 78.6611 24.1749C77.5081 22.4455 76.9316 20.4653 76.9316 18.2346C76.9316 15.9536 77.5081 13.961 78.6611 12.2566C79.8141 10.5521 81.4183 9.2237 83.4736 8.27123C85.5289 7.31876 87.9352 6.84253 90.6923 6.84253C93.4244 6.84253 95.8181 7.31876 97.8734 8.27123C99.9538 9.2237 101.57 10.5521 102.723 12.2566C103.876 13.961 104.453 15.9536 104.453 18.2346C104.453 20.4653 103.876 22.4455 102.723 24.1749C101.57 25.8794 99.9538 27.2078 97.8734 28.1603C95.8181 29.0877 93.4244 29.5514 90.6923 29.5514ZM90.6923 23.8742C91.9456 23.8742 93.0108 23.6486 93.8881 23.1974C94.7904 22.7462 95.4797 22.0946 95.9559 21.2423C96.4322 20.3901 96.6703 19.3875 96.6703 18.2346C96.6703 17.0314 96.4322 16.0163 95.9559 15.1892C95.4797 14.337 94.7904 13.6853 93.8881 13.2341C93.0108 12.7829 91.9456 12.5573 90.6923 12.5573C89.4391 12.5573 88.3613 12.7829 87.4589 13.2341C86.5566 13.6853 85.8673 14.337 85.3911 15.1892C84.9148 16.0163 84.6767 17.0189 84.6767 18.197C84.6767 19.375 84.9148 20.3901 85.3911 21.2423C85.8673 22.0946 86.5566 22.7462 87.4589 23.1974C88.3613 23.6486 89.4391 23.8742 90.6923 23.8742Z" fill="#1A1311"/>
<path d="M60.873 35.8949C59.2939 35.8949 57.8903 35.6943 56.6621 35.2933C55.4339 34.9173 54.2433 34.3283 53.0903 33.5262V28.1874C54.2684 28.9393 55.3712 29.4782 56.3989 29.8041C57.4266 30.1299 58.5545 30.2928 59.7827 30.2928C60.8103 30.2928 61.7252 30.0673 62.5273 29.6161C63.3294 29.1649 63.9811 28.3378 64.4823 27.1347L72.7162 7.62158H80.8748L70.5731 29.5033C69.8463 31.0824 68.9564 32.3356 67.9037 33.263C66.8761 34.2155 65.7607 34.8923 64.5575 35.2933C63.3544 35.6943 62.1262 35.8949 60.873 35.8949ZM61.1362 27.0971L52 7.62158H60.4594L68.5429 27.0971H61.1362Z" fill="#1A1311"/>
<path d="M44.436 29.5514C41.8292 29.5514 39.4982 29.0752 37.4428 28.1227C35.4126 27.1702 33.8084 25.8418 32.6304 24.1374C31.4523 22.4079 30.8633 20.4027 30.8633 18.1218C30.8633 15.891 31.4272 13.9359 32.5552 12.2566C33.6831 10.5521 35.2246 9.2237 37.1797 8.27123C39.1598 7.31876 41.3906 6.84253 43.872 6.84253C46.4537 6.84253 48.6469 7.40649 50.4515 8.53441C52.2813 9.63727 53.6849 11.2038 54.6625 13.2341C55.6651 15.2644 56.1664 17.6581 56.1664 20.4152H37.0669V15.7907H51.8427L49.3236 17.4074C49.2234 16.3046 48.9477 15.3771 48.4965 14.6252C48.0453 13.8482 47.4438 13.2592 46.6918 12.8581C45.9649 12.4571 45.0876 12.2566 44.06 12.2566C42.9321 12.2566 41.9671 12.4821 41.165 12.9333C40.3629 13.3845 39.7363 14.0111 39.2851 14.8132C38.8339 15.5902 38.6084 16.5051 38.6084 17.5578C38.6084 18.9113 38.9091 20.0643 39.5107 21.0168C40.1373 21.9442 41.0397 22.6585 42.2177 23.1598C43.4208 23.6611 44.8871 23.9118 46.6166 23.9118C48.1957 23.9118 49.7623 23.7112 51.3163 23.3102C52.8954 22.8841 54.3241 22.2951 55.6024 21.5431V26.4308C54.1236 27.4334 52.4317 28.2104 50.5267 28.7618C48.6469 29.2882 46.6166 29.5514 44.436 29.5514Z" fill="#1A1311"/>
<path d="M21.017 15.1518L21.6561 13.6855C23.8368 13.7356 25.6289 14.074 27.0326 14.7006C28.4613 15.3022 29.5265 16.1544 30.2283 17.2572C30.9302 18.335 31.2811 19.5883 31.2811 21.017C31.2811 22.5459 30.9302 23.912 30.2283 25.1151C29.5265 26.2931 28.4738 27.2331 27.0702 27.9349C25.6916 28.6117 23.9621 28.95 21.8817 28.95H1.05273L3.23339 14.475L1.05273 0.751953H21.1298C23.7365 0.751953 25.7668 1.36604 27.2206 2.59423C28.6743 3.79735 29.4012 5.4767 29.4012 7.63229C29.4012 8.81034 29.1255 9.93826 28.5741 11.0161C28.0226 12.0938 27.1328 13.0087 25.9046 13.7607C24.7015 14.4876 23.0723 14.9513 21.017 15.1518ZM8.76021 27.1454L5.75241 23.4984H19.7763C20.9042 23.4984 21.7815 23.2227 22.4081 22.6713C23.0347 22.1198 23.348 21.3554 23.348 20.3778C23.348 19.4003 23.0096 18.6233 22.3329 18.0468C21.6561 17.4452 20.6535 17.1444 19.3251 17.1444H7.74508V11.8056H18.2724C19.1747 11.8056 19.9266 11.5424 20.5282 11.0161C21.1548 10.4646 21.4681 9.72521 21.4681 8.79781C21.4681 8.04586 21.205 7.43177 20.6786 6.95553C20.1522 6.45423 19.4003 6.20358 18.4228 6.20358H5.79001L8.76021 2.55663L10.6777 14.475L8.76021 27.1454Z" fill="#1A1311"/>
</svg>
`;
interface IndexPageProps {
  data: AboutQuery;
}

const Body = styled.div`
  overflow-x: hidden;
`;

// ↓↓↓共通パーツ系↓↓↓
const AboutText = styled.div`
  font-family: ${fonts.enSans};
  p {
    & + p {
      margin-top: 40px;
      ${mq.onlysp} {
        margin-top: 32px;
      }
    }
    br {
      ${mq.onlysp} {
        display: none;
      }
    }
  }
`;
const InfoBox = styled.div`
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr auto;
  column-gap: 20px;

  & + & {
    margin-top: 136px;
  }

  ${mq.onlysp} {
    margin-top: 40px;
    display: block;
  }
`;
const InfoBoxImage = styled.img`
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  width: 275px;
  height: auto;
  filter: grayscale(100%);

  ${mq.onlysp} {
    margin-top: 30px;
    width: 100%;
  }
`;
const InfoBoxHeading = styled.div`
  font-family: ${fonts.enSans};
  ${text(24, 1.5)};
  grid-column: 1 / 2;
  font-weight: 600;
  margin-bottom: 40px;
  ${mq.onlysp} {
    ${text(18, 1.5)};
    margin-bottom: 25px;
    font-weight: 600; // textのmixinによってweightが上書きされるためさらに上書き
  }
`;
const InfoBoxContent = styled.div`
  font-family: ${fonts.enSans};
  grid-column: 1 / 2;
  ${text(16, 1.8)}
  letter-spacing: 0em;

  [data-lang='en'] & {
    ${text(22, 1.8)}
    ${mq.onlysp} {
      ${text(18, 1.8)}
    }
  }
`;
const InfoBoxLink = styled.div`
  grid-column: 1 / 2;
  margin-top: 60px;
  ${mq.onlysp} {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
`;
const AboutEnHeading = styled(EnHeading)`
  ${text(32, 2)}
  letter-spacing: 0;
  ${mq.onlysp} {
    ${text(24, 2)}
  }
`;
const AboutJaHeading = styled(JaHeading)`
  ${text(20, 2)}
  margin-top: 22px;
  font-weight: 600;
  ${mq.onlysp} {
    ${text(15, 2)}
    font-weight: 600; // textのmixinによってweightが上書きされるためさらに上書き
    margin-top: 15px;
  }
`;
const ContentSection = styled.div`
  max-width: 1860px;
`;
const AboutStudioCard = styled.div`
  & + & {
    ${mq.onlysp} {
      margin-top: 32px;
    }
  }
`;
// ↑↑↑共通パーツ系↑↑↑

// ↓↓↓KVSection↓↓↓
const KVSection = styled.div`
  position: relative;

  ${mq.onlypc} {
    margin-left: max(calc((100vw - ${contentWidthPc}px) / 2), 40px);
    min-width: ${contentWidthPc}px;
  }
`;
const KVSectionBlock = styled.div`
  &:first-child {
    position: relative;
    height: ${478 + 180}px;
    background-image: url(${KVImage});
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
    padding-top: 180px;
    ${mq.onlypc} {
      margin-left: 60px;
    }
    ${mq.onlysp} {
      background-image: url(${KVImageSp});
      padding-top: 155px;
      margin-left: 97px;
      height: ${298 + 155}px;
    }
  }

  &:last-child {
    width: 629px;
    margin-top: 80px;
    ${mq.onlypc} {
      transform: translateX(-40px);
      margin-right: max(calc((100vw - ${contentWidthPc}px) / 2), 80px);
      margin-left: auto;
    }

    ${mq.onlysp} {
      padding: 0 ${sizes.margin[32]};
      margin-top: 64px;
      width: 100%;
    }
  }
`;
const KVSectionHeading = styled(EnHeading)`
  transform: translateX(-60px);
`;
const KVSectionText = styled(AboutText)`
  p {
    ${text(24, 1.8)}
    font-weight: 600;
    letter-spacing: 0em;
    -webkit-text-stroke: 0.2px currentColor;
    text-stroke: 0.2px currentColor;

    ${mq.onlysp} {
      ${text(17, 1.8)}
      font-weight: 600; // textのmixinによってweightが上書きされるためさらに上書き
    }
  }
`;
const KVSectionLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${mq.onlypc} {
    margin-top: 40px;
  }
  ${mq.onlysp} {
    margin-top: 50px;
  }
`;
const KVSectionLink = styled(IndexLink)`
  display: inline-block;
  position: relative;
  height: auto;
  cursor: pointer;
`;
const KVSectionLinkInner = styled.div`
  font-family: ${fonts.enGothic};
  ${text(20, 1.25)};
`;
const KVSectionLinkArrow = styled(Arrow)`
  width: 32px;
  height: 32px;
  margin-left: 8px;
`;
const KVSectionCopy = styled.div`
  position: absolute;
  bottom: 0;
  left: -60px;

  ${mq.onlysp} {
    position: relative;
    margin-left: -60px;
    margin-top: 120px;
    bottom: 0;
    left: 0;
  }
`;
// ↑↑↑KVSection↑↑↑

// ↓↓↓コンテンツ部分↓↓↓
const AboutContentSection = styled.div`
  position: relative;
  ${mq.onlypc} {
    max-width: 1160px;
  }

  ${mq.onlysp} {
    max-width: unset;
    margin-left: auto;
    margin-right: auto;
  }

  &:last-of-type {
    margin-bottom: 180px;
    ${mq.onlysp} {
      margin-bottom: 120px;
    }
  }
`;
// Studiosセクション
const AboutStudiosSection = styled(AboutContentSection)`
  ${mq.onlypc} {
    display: block;
    max-width: unset;
    margin-top: 190px;
    justify-content: center;
  }
  ${mq.onlysp} {
    margin-top: 113px;
  }
`;
const AboutStudiosSectionCardCol = styled.div`
  margin-left: auto;
  margin-right: 0;

  ${mq.onlypc} {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: ${sizes.margin[17]};
  }

  ${mq.onlysp} {
    margin-top: 35px;
  }
`;
// Infoセクション
const AboutInfoSection = styled(AboutContentSection)`
  ${mq.onlypc} {
    display: block;
    margin-top: 175px;
  }
  ${mq.onlysp} {
    margin-top: 95px;
  }
`;
const AboutInfoSectionInner = styled.div`
  ${mq.onlypc} {
    margin-top: 51px;
    margin-left: 296px;
  }
  ${mq.onlysp} {
    margin-top: 32px;
  }
`;
const AboutInfoSectionText = styled(AboutText)`
  p {
    ${text(18, 1.8)}
    font-family: ${fonts.enSans};
    letter-spacing: 0em;
    ${mq.onlysp} {
      ${text(16, 1.8)}
    }
  }
`;
// ↑↑↑コンテンツ部分↑↑↑

const Pages: React.FC<IndexPageProps> = props => {
  const isSP = useIsSp();
  const layout = isSP ? 'sp' : 'pc';

  const studios = props.data.allShifterStudiosData.edges;

  const info = [
    {
      image: MfsImg,
      imageAlt: '',
      heading: 'MOM FoR STAR',
      content:
        'MOM FoR STAR is a web design employment and collaboration project aimed at single mothers in Okinawa. By breaking down the tasks undertaken by FOURDIGIT and creating an operation and learning flow, we have developed a program that serves as an entry point into web-related work. This initiative supports skill and career development, increasing the number of colleagues engaged in digital design.',
      link: 'https://www.momforstar.jp/',
    },
  ];

  return (
    <Body>
      <SEO title={'About'} en />
      <KVSection>
        <KVSectionBlock>
          <KVSectionHeading>About</KVSectionHeading>
          <KVSectionCopy>
            <div dangerouslySetInnerHTML={{ __html: layout === 'pc' ? ABOUT_TEXT_SVG_PC : ABOUT_TEXT_SVG_SP }} />
          </KVSectionCopy>
        </KVSectionBlock>

        <KVSectionBlock>
          <KVSectionText>
            <p>
              FOURDIGIT is a professional partner for businesses, connecting them with users through the power of
              design. By developing and launching services and products based on a thorough understanding of users, we
              accelerate business growth and create impacts that contribute to a better society.
            </p>
            <p>
              From Japan, a country at the forefront of mature markets and the first to enter the phase of a declining
              birthrate and aging population, to Southeast Asia, where the youth dominate and new values emerge daily.
              With branches in various cities, we collaborate with teams that have an intrinsic understanding of local
              cultures and philosophies, leading the design market beyond all boundaries and frameworks.
            </p>
          </KVSectionText>
          <KVSectionLinkWrapper>
            <KVSectionLink to={`${links[0].link}`}>
              <KVSectionLinkInner>{links[0].name}</KVSectionLinkInner>
            </KVSectionLink>
            <KVSectionLinkArrow hoverAnime />
          </KVSectionLinkWrapper>
        </KVSectionBlock>
      </KVSection>

      <ContentWrapper>
        <ContentSection>
          <AboutStudiosSection>
            <FadeUpSection moveBig>
              <AboutEnHeading>Studios</AboutEnHeading>
              <AboutJaHeading>To a place where design is needed</AboutJaHeading>

              <AboutStudiosSectionCardCol>
                {studios.map(studio => (
                  <AboutStudioCard key={studio.node.id}>
                    <StudioCard
                      imgpath={studio.node.acf?.thumb || 'noimage/img-card-thumb.jpg'}
                      {...(studio.node.acf?.detail_page ? { to: `/en/about/studios/${studio.node.slug}` } : {})}
                      place={studio.node.title?.rendered || ''}
                      type="about"
                    />
                  </AboutStudioCard>
                ))}
              </AboutStudiosSectionCardCol>
            </FadeUpSection>
          </AboutStudiosSection>

          <AboutInfoSection>
            <FadeUpSection moveBig>
              <AboutEnHeading>Sustainability</AboutEnHeading>
              <AboutJaHeading>Designing Sustainability</AboutJaHeading>
              <AboutInfoSectionInner>
                <AboutInfoSectionText>
                  <p>
                    FOURDIGIT&apos;s sustainability initiatives are based on the philosophy of Creating Shared Value
                    (CSV), where businesses address social issues through their activities.
                  </p>
                  <p>
                    Through skill enhancement support provided by members driving FOURDIGIT's design business and
                    mechanisms that allow program participants to apply their acquired skills and insights, we design
                    systems that create economic benefits while enabling mutual growth for both organizations and their
                    members.
                  </p>
                </AboutInfoSectionText>

                {info.map((item, index) => (
                  <InfoBox key={index}>
                    <InfoBoxHeading>{item.heading}</InfoBoxHeading>
                    <InfoBoxContent>{item.content}</InfoBoxContent>
                    <InfoBoxImage src={item.image} alt={item.imageAlt} />
                    {item.link ? (
                      <InfoBoxLink>
                        <Button href={item.link} type={'fill'} blank>
                          Visit Website
                        </Button>
                      </InfoBoxLink>
                    ) : null}
                  </InfoBox>
                ))}
              </AboutInfoSectionInner>
            </FadeUpSection>
          </AboutInfoSection>
        </ContentSection>
      </ContentWrapper>
      <InquiryBlock en />
    </Body>
  );
};

export default Pages;

export const pageQuery = graphql`
  query AboutEn {
    site {
      siteMetadata {
        title
      }
    }
    allShifterStudiosData(
      filter: { acf: { country: { in: ["JAPAN", "VIETNAM", "THAILAND", "MALAYSIA"] } } }
      sort: { order: ASC, fields: acf___show_num }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            thumb
            country
            text
            text_en
            address
            contact
            map
            show_num
            sns {
              type
              url
            }
            detail_page
          }
        }
      }
    }
  }
`;
